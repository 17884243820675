import { useCustomEffect } from "../components/useCustomEffectNews";
import {Link} from  "react-router-dom";
import "../styles/News.css";
import NewsItem from "../components/NewsItem";
import StoriesModal from "../components/StoriesModal";
import { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRedo } from "@fortawesome/free-solid-svg-icons";


function News() {
    /* 배열 */

    /* 그리드 변경 customHook */
    const { sep1Active, sep2Active, sep1Grid, sep2Grid, sep3Grid, cardsToShow, searchTerm, onRefresh, inputValue, setInputValue, handleSearch, showMore, handleShowMore, newsArray } = useCustomEffect()
        
    /* 모달 popup */
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ title: "", content: "", image: null, image2: null, author: "", write_date: "", update_date: "", article_url: "", article_name:"", youtube_url:""});
    console.log(modalInfo, 'modalInfo')
    const handleCardClick = (title, content, image, image2, author, write_date, update_date, article_url, article_name, youtube_url) => {
        setModalInfo({ title, content, image, image2, author, write_date, update_date, article_url, article_name, youtube_url});
        setShowModal(true);
        document.body.style.overflow = 'hidden';

        const screenWidth = window.innerWidth;
        if(screenWidth > 767 && sep1Active) {
            setShowModal(false);
            document.body.style.overflow = '';
        }
    };

    const closeModal = (e) => {
        if(e.target === e.currentTarget) {
            setShowModal(false);
            document.body.style.overflow = '';
        }
    };
    
    return (
        <div className='stories news'>
            <div className='stories_top'>
                <p className='breadCrumb inner'>
                <Link to="/">HOME</Link> &gt; <Link to="/stories/news">정도이야기</Link> &gt; <span> 정도소식 </span>
                </p>
            </div>
            <div className='stories_main inner'>
                <div className='stories_main_title'>정도소식</div>
                <div className='search'>
                    <form onSubmit={handleSearch}>
                        <input type="text" placeholder='검색어를 입력하세요.'  value={inputValue} onChange={(event) => {setInputValue(event.target.value);}} />
                        <button type="submit" className="searchBtn"><FontAwesomeIcon icon={faSearch} /></button>
                        <button className="refreshBtn" onClick={onRefresh}><FontAwesomeIcon icon={faRedo} /></button>
                    </form>
                </div>
                <div className='grid_btn'>
                    <div className='grid_btn_sep1' onClick={sep1Grid}></div>
                    <div className='grid_btn_sep2'onClick={sep2Grid}>
                        <div className='sep2_box left'></div>
                        <div className='sep2_box right'></div>
                    </div>
                    <div className='grid_btn_sep3 'onClick={sep3Grid}>
                        <div className='top'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                        <div className='bottom'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                    </div>
                </div>
                <div className={`post_box_list ${sep1Active ? "sep1": ""}${sep2Active ? "sep2": ""}`}>
                    {newsArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter((data) => {
                        if (searchTerm === ''){
                            return data;
                        }else if(
                            data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            data.content.toLowerCase().includes(searchTerm.toLowerCase())
                        ) {
                            return data;
                        }
                    }).map((data) => (
                        <NewsItem 
                        key={data.board_id} 
                        title={data.title} 
                        content={data.content} 
                        image={data.image}
                        image2={data.image2}
                        author={data.author} 
                        write_date={data.write_date}
                        update_date={data.update_date}
                        article_url={data.article_url}
                        article_name={data.article_name}
                        youtube_url={data.youtube_url} 
                        onClick={() => {handleCardClick(data.title, data.content, data.image, data.image2, data.author, data.write_date, data.update_date, data.article_url, data.article_name, data.youtube_url)}} />
                    ))}
                </div>
                {searchTerm !== "" && newsArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter(data =>
                    data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    data.content.toLowerCase().includes(searchTerm.toLowerCase())
                ).length === 0 && (
                <div className="nothing">검색 결과가 없습니다.</div>
                )}
                {searchTerm === "" && cardsToShow<newsArray.length && (<button className="viewMore" onClick={handleShowMore}>{showMore ? "더보기+":"접기"}</button>)}
                <StoriesModal 
                    showModal={showModal} 
                    closeModal={closeModal} 
                    key={modalInfo.id} 
                    title={modalInfo.title} 
                    content={modalInfo.content} 
                    image={modalInfo.image}
                    image2={modalInfo.image2}
                    author={modalInfo.author} 
                    write_date={modalInfo.write_date}
                    article_url={modalInfo.article_url}
                    article_name={modalInfo.article_name}
                    youtube_url={modalInfo.youtube_url}
                 />
            </div>
        </div>
    )
}

export default News