import React from 'react';

function VictoryModal({ showModal, closeModal, title, content, author, write_date, update_date, keyword1, keyword2, keyword3, keyword4}) {

    if (!showModal) return null;

    return (
        <div className={`modal_bg ${showModal ? "visible": ""}`} onClick={closeModal}>
            <div className={`modal ${showModal ? "visible": ""} inner`}>
            <div className='post_box'>
                {!keyword1 && !keyword2 && !keyword3 && !keyword4 ?
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                :!keyword2 && !keyword3 && !keyword4 ?
                    <ul className='post_box_top_tag'>
                        <li>#{keyword1}</li>
                    </ul>
                :!keyword3 && !keyword4 ?
                    <ul className='post_box_top_tag'>
                        <li>#{keyword1}</li>
                        <li>#{keyword2}</li>
                    </ul>
                :!keyword4?
                    <ul className='post_box_top_tag'>
                        <li>#{keyword1}</li>
                        <li>#{keyword2}</li>
                        <li>#{keyword3}</li>
                    </ul>
                :
                    <ul className='post_box_top_tag'>
                        <li>#{keyword1}</li>
                        <li>#{keyword2}</li>
                        <li>#{keyword3}</li>
                        <li>#{keyword4}</li>
                    </ul>
                }
                <div className='post_box_title_area'>
                    <p className="post_box_title">
                        {title? title:"제목을 입력해 주세요"}
                    </p>
                    <ul className="post_box_info">
                        <li className="info_member">담당변호사 {author/*  ? member:"정보없음" */}</li>
                        <li className="info_date">{write_date} (마지막 수정일: {update_date})</li>
                    </ul>
                </div>
                    <div className="post_box_content">
                        <div className='post_box_desc'>
                            {content}
                        </div>
                    </div>
                    <button className='modal_close_btn' onClick={closeModal}>목록으로</button>
            </div>
            </div>
        </div>
    )
}

export default VictoryModal