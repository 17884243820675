import React from 'react';
import {Link} from  "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "../styles/StoriesModal.css";

function StoriesModal({ showModal, closeModal, title, content, image, image2, author, write_date, article_url, article_name, youtube_url}) {

    if (!showModal) return null;
    return (
        <div className={`modal_bg ${showModal ? "visible": ""}`} onClick={closeModal}>
            <div className={`modal ${showModal ? "visible": ""} inner`}>
                <div className='post_box'>
                    {/* <ul className='post_box_top_tag'>
                        <li>#{tag1}&nbsp;</li>
                        <li>#{tag2}&nbsp;</li>
                        <li>#{tag3}&nbsp;</li>
                        <li>#{tag4}</li>
                    </ul> */}

                    <div className='post_box_title_area'>
                        <p className="post_box_title">
                            {title? title:"제목을 입력해 주세요"}
                        </p>
                        <ul className="post_box_info">
                            <li className="info_member">담당변호사 {author ? author:"정보없음"}</li>
                            <li className="info_date">{write_date}</li>
                        </ul>
                    </div>
                    <div className="post_box_content">
                        <div className='post_box_desc'>
                            {youtube_url ? (
                                <div className='post_youtube_area'>
                                    <iframe
                                        title='myFrame'
                                        width="380"
                                        height="315"
                                        src={youtube_url} 
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>

                                    </iframe>
                                </div>
                            ) : (
                                <div className='empty'></div>
                            )}
                            {image && !image2 ? (
                                <div className='post_img_area'>
                                    <img className="post_img" src={image} alt='소식이미지' width={600}/>
                                </div>
                            ) :
                            image2 && !image ? (
                                <div className='post_img_area'>
                                    <img className="post_img" src={image2} alt='소식이미지' width={600}/>
                                </div>
                            ) : 
                            image && image2 ? (
                                <div className='post_img_area'>
                                    <img className="post_img" src={image} alt='소식이미지' width={450}/>
                                    <img className="post_img" src={image2} alt='소식이미지' width={450}/>
                                </div>
                            ) :
                                <div className='empty'></div>
                            }
                            {content}
                        </div>
                        <div className='post_link'>
                            {article_url ? <Link to = {article_url}><FontAwesomeIcon icon={faLink} />{article_name ? article_name : article_url}</Link> : <p className='empty'></p>}
                        </div>
                    </div>
                    <button className='modal_close_btn' onClick={closeModal}>목록으로</button>
                </div>
            </div>
        </div>
    )
}

export default StoriesModal