import React, {useState, useEffect} from "react";
import { useCustomEffect } from "../components/useCustomEffectLaws";
import {Link} from  "react-router-dom";
import "../styles/Law.css";

import LawItem from "../components/LawItem";
import LawModal from "../components/LawModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRedo } from "@fortawesome/free-solid-svg-icons";

function Law() {
    /* 배열 */    
    /* 그리드 변경 customHook */
    const { sep1Active, sep2Active, sep1Grid, sep2Grid, sep3Grid, cardsToShow, searchTerm, onRefresh, inputValue, setInputValue, handleSearch, showMore, handleShowMore, lawsArray } = useCustomEffect()

    /* 모달 popup */
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ title: "", content: "", author: "", write_date: "", update_date: "", keyword1: "", keyword2: "", keyword3: "", keyword4: ""});

    const handleCardClick = (title, content, author, write_date, update_date, keyword1, keyword2, keyword3, keyword4) => {
        setModalInfo({title, content, author, write_date, update_date, keyword1, keyword2, keyword3, keyword4});
        setShowModal(true);
        document.body.style.overflow = 'hidden';

        const screenWidth = window.innerWidth;
        if(screenWidth > 767 && sep1Active) {
            setShowModal(false);
            document.body.style.overflow = 'visible';
        }
    };

    const closeModal = (e) => {
        if(e.target === e.currentTarget) {
            setShowModal(false);
            document.body.style.overflow = '';
        }
    };

    return (
        <div className='stories law'>
            <div className='stories_top'>
                <p className='breadCrumb inner'>
                    <Link to="/">HOME</Link> &gt; <Link to="/stories/news">정도이야기</Link> &gt; <span> 법률정보 </span>
                </p>
            </div>
            <div className='stories_main inner'>
                <div className='stories_main_title'>법률정보</div>
                <div className='search'>
                    <form onSubmit={handleSearch}>
                        <input type="text" placeholder='검색어를 입력하세요.' value={inputValue} onChange={(event) => {setInputValue(event.target.value);}} />
                        <button type="submit" className="searchBtn"><FontAwesomeIcon icon={faSearch} /></button>
                        <button className="refreshBtn" onClick={onRefresh}><FontAwesomeIcon icon={faRedo} /></button>
                    </form>
                </div>
                <div className='grid_btn'>
                    <div className='grid_btn_sep1' onClick={sep1Grid}></div>
                    <div className='grid_btn_sep2'onClick={sep2Grid}>
                        <div className='sep2_box left'></div>
                        <div className='sep2_box right'></div>
                    </div>
                    <div className='grid_btn_sep3 'onClick={sep3Grid}>
                        <div className='top'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                        <div className='bottom'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                    </div>
                </div>
                <div className={`post_box_list ${sep1Active ? "sep1": ""}${sep2Active ? "sep2": ""}`}>
                    {lawsArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter((data) => {
                        if (searchTerm === ''){
                            return data;
                        }else if(
                            data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            data.content.toLowerCase().includes(searchTerm.toLowerCase())
                        ) {
                            return data;
                        }
                    }).map((data) => (
                        <LawItem 
                        key={data.board_id} 
                        title={data.title} 
                        author={data.author}
                        content={data.content}
                        keyword1={data.keyword1}
                        keyword2={data.keyword2}
                        keyword3={data.keyword3}
                        keyword4={data.keyword4}
                        write_date={data.write_date}
                        update_date={data.update_date}
                        onClick={() => {handleCardClick(data.title, data.content, data.author, data.write_date, data.update_date, data.keyword1, data.keyword2, data.keyword3, data.keyword4)}} />
                    ))}
                </div>
                {searchTerm !== "" && lawsArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter(data =>
                    data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    data.content.toLowerCase().includes(searchTerm.toLowerCase())
                ).length === 0 && (
                <div className="nothing">검색 결과가 없습니다.</div>
                )}
                {searchTerm === "" && cardsToShow<lawsArray.length && (<button className="viewMore" onClick={handleShowMore}>{showMore ? "더보기+":"접기"}</button>)}
                <LawModal 
                    showModal={showModal} 
                    closeModal={closeModal}
                    title={modalInfo.title} 
                    author={modalInfo.author} 
                    content={modalInfo.content} 
                    keyword1={modalInfo.keyword1}
                    keyword2={modalInfo.keyword2}
                    keyword3={modalInfo.keyword3}
                    keyword4={modalInfo.keyword4}
                    write_date={modalInfo.write_date}
                    update_date={modalInfo.update_date}
                 />
            </div>
        </div>
    )
}

export default Law