import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './RouterComponent';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SideMenu from "./components/SideMenu/SideMenu";




import "./styles/reset.css";
import "./styles/common.css";
import "./styles/App.css";



function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Header></Header>
            <RouterComponent />
            <Footer></Footer>
            <SideMenu></SideMenu>
        </div>
    </BrowserRouter>
  );
}

export default App;
