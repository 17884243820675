import React from 'react';
import {Link} from 'react-router-dom';
import {Fade} from 'react-awesome-reveal'

import "../styles/Introduce.css";

import intro1 from '../img/intro1.jpg'
import intro2 from '../img/intro2-1.jpg'
import intro3 from '../img/intro3-1.jpg'
import intro4 from '../img/intro4-1.jpg'

function Introduce() {
  return (
    <div className='intro'>
        <div className='intro_top'>
            <p className='breadCrumb inner'>
                <Link to="/">HOME</Link> &gt; <Link to="/about/introduce">정도소개</Link> &gt; <span> 정도(正道)</span>
            </p>
            <div className='title'>
                <h1 className='title_name1'>正道</h1>
                <h2 className='title_name2'>
                    <span>바를 정</span>
                    <span>길 도</span>
                </h2>
                <p className='title_name3'>
                    법무법인 정도(正道)는<br />바른 길을 걸어 나가겠습니다.
                </p>
            </div>
        </div>
        <div className='inner'>
            <div className='intro_main'>
                <Fade duration={1700}>
                    <div className='intro_box lf'>
                        <div className='img_box'>
                            <img src={intro1} alt="소개이미지1" />
                        </div>
                        <div className='txt_box'>
                            <h3 className='txt_name'>정도를 걷습니다.</h3>
                            <p className='txt_detail'>
                                법무법인 정도는 각 분야에서 활발하게 활동하던 <span>8명의 변호사</span>들이 <br />
                                의기투합하여 2012년 3월에 설립한 종합 법무법인입니다.<br />
                                각자 다른 이력을 가진 법무법인 등에서 일하면서 고객의 이익과<br />
                                구성원의 이익을 등한시하고 자신의 이익만을 추구하는 대표,<br />
                                또는 다른 변호사들의 모습을 보면서 <span>올바른 변호사로서의 모습</span>을 갈망하여<br />
                                한뜻으로 뭉치게 되었습니다.<br />
                                <br />
                                법무법인 정도의 이름에 걸맞게 의뢰인에게 솔직한 모습,<br />
                                법원과 검찰에 당당한 모습, 기업 자문 업무에서 성실한 모습으로<br />
                                <span>바른길을 걸어 나가겠습니다.</span>
                            </p>
                        </div>
                    </div>
                </Fade>
                <Fade duration={1700}>
                    <div className='intro_box rg'>
                        <div className='img_box'>
                            <img src={intro2} alt="소개이미지2" />
                        </div>
                        <div className='txt_box txt_box2'>
                            <h3 className='txt_name'>정도는 노련하면서 열정적입니다.</h3>
                            <p className='txt_detail'>
                                
                            법무법인 정도의 변호사들은 <span>변호사 경력 12년 ~ 20년</span>사이의 변호사들로서  <br />
                            변호사들 각자가 다양한 소송과 자문 경력을 가지고 있습니다.  <br />
                            실무 감각이 뛰어난 <span>경험 많은 변호사</span>들이지만  <br />
                            <span>모든 변호사들이 열정적이고 적극적으로</span> 업무에 임하고 있습니다.    <br />
                            <br />    
                            법무법인 정도에는 고용된 주니어 변호사가 없습니다.  <br />
                            시니어 변호사는 상담만 하고 경력이 미천한 변호사, <br />사무직원에게 일을 맡기는 것이 아니라 <br />
                            <span> 모든 변호사들이 직접 소송과 자문을 수행</span>합니다.  <br />
                            <br />
                            이것이 이름만 대형인 다른 법무법인들과 법무법인 정도의 <span>가장 큰 차이점</span>입니다. <br />
                            </p>
                        </div>
                    </div>
                </Fade>
                <Fade duration={1700}>
                    <div className='intro_box lf'>
                        <div className='img_box'>
                            <img src={intro3} alt="소개이미지3" />
                        </div>
                        <div className='txt_box'>
                            <h3 className='txt_name'>
                                정도는 다양한 경험과
                                <br />
                                실무 노하우를 가지고 있습니다.
                            </h3>
                            <p className='txt_detail'>
                                
                            <span>이한본 변호사</span>는 여성가족부 자문변호사 3년, 국가기관, 지방자치단체에서 <br/>다수의 위원회 위원으로 활동, <br/>
                            <span>김병재 변호사</span>는 중소기업 법률 지원단 활동,  <br/>
                            <span>양창영 변호사</span>는 공정거래 분야에서 최고의 전문가로서 <br/> 서울특별시, 중소벤처기업부의 위원회에서 위원으로 활동,  <br/>
                            <span>설창일 변호사</span>는 자문을 담당하는 기업만 20여개로 다양한 기업 자문 활동,  <br/>
                            <span>김지미 변호사</span>는 서울 남부지방법원 국선전담 변호인으로 <br/> 5년 이상 활동하는 등 형사 전문 변호사 활동, <br/>
                            <br/>
                            이와 같이 <span>전문성</span>을 인정받아 <span>다양한 분야</span>에서 활동을 하고 있습니다. 
                            </p>
                        </div>
                    </div>
                </Fade>
                <Fade duration={1700}>
                    <div className='intro_box rg'>
                        <div className='img_box'>
                            <img src={intro4} alt="소개이미지4" />
                        </div>
                        <div className='txt_box'>
                            <h3 className='txt_name'>
                                정도는 고객 여러분들의<br />
                                그늘이 되겠습니다.
                            </h3>
                            <p className='txt_detail'>
                                우리 삶의 모든 부분은 법으로 규율이 되고 있습니다. <br />
                                평상시 인식하지 못하고 하는 모든 행동들은 대부분 법적인 행위들입니다. <br />
                                너무나 소중한 햇빛이 가끔은 뜨겁거나 눈부셔서 <br />
                                우리에게 불편과 고통을 주는 일이 있는 것처럼 <br />
                                법이 실제로 발동이 되었을 때의 불편과 고통을 정도는 잘 알고 있습니다.<br />
                                <br />
                                우리 삶의 질서인 법이 우리를 뜨겁게, 눈부시게 할 때 <br />
                                법무법인 정도는 <span>여러분의 그늘</span>이 되어드릴 것입니다.
                            </p>
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <button className='btn_asks'>
                        <Link to="/asks">온라인 상담신청 바로가기</Link>
                    </button>
                </Fade>
            </div>
        </div>
    </div>
  )
}

export default Introduce