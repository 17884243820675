import React from 'react'
import {Link} from  "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";



function NewsItem({ title, content, image, image2, author, write_date, update_date, article_url, article_name, youtube_url, onClick}) { //onclick: 모달열림
  return (
    <div className='post_box'>
        {/* <ul className='post_box_top_tag'>
            <li>#{tag1}&nbsp;</li>
            <li>#{tag2}&nbsp;</li>
            <li>#{tag3}&nbsp;</li>
            <li>#{tag4}</li>
        </ul> */}
        <div className='post_box_title_area' onClick={onClick}>
            <p className="post_box_title">
                {title? title:"제목을 입력해 주세요"}
            </p>
            <ul className="post_box_info">
                <li className="info_member">담당변호사 {author ? author:"정보없음"}</li>
                <li className="info_date">{write_date} (마지막 수정일: {update_date})</li>
            </ul>
        </div>
            <div className="post_box_content">
                <div className='post_box_desc' onClick={onClick}>
                    {youtube_url ? (
                        <div className='post_youtube_area'>
                            <iframe
                                title='myFrame'
                                width="510"
                                height="315"
                                src={youtube_url} 
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    ) : (
                        <div className='empty'></div>
                    )}
                    {image && !image2 ? (
                        <div className='post_img_area'>
                            <img className="post_img" src={image} alt='소식이미지' width={500}/>
                        </div>
                    ) :
                    image2 && !image ? (
                        <div className='post_img_area'>
                            <img className="post_img" src={image2} alt='소식이미지' width={500}/>
                        </div>
                    ) : 
                    image && image2 ? (
                        <div className='post_img_area'>
                            <img className="post_img" src={image} alt='소식이미지' width={400}/>
                            <img className="post_img" src={image2} alt='소식이미지' width={400}/>
                        </div>
                    ) :
                        <div className='empty'></div>
                    }
                    {content}
                </div>
                <div className='post_link'>
                    {article_url ? <Link to = {article_url}><FontAwesomeIcon icon={faLink} />{article_name ? article_name : article_url}</Link> : <p className='empty'></p>}
                </div>
            </div>
        <div className='post_box_detail' onClick={onClick}>
            <p className='more_btn'>자세히보기</p>
            <p className="post_date">{write_date}</p>
        </div>
    </div>
  )
}

export default NewsItem