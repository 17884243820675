import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';

import Home from "./pages/Home";
import Works from "./pages/Works";
import Members from "./pages/Members";
import Asks from "./pages/Asks";

import Introduce from "./pages/Introduce";
import Location from "./pages/Location";

import News from './pages/News';
import Victory from './pages/Victory';
import Law from './pages/Law';
import Admin from './pages/Admin';
import Adminlist from './pages/Adminlist';
import Write from './pages/Write';
import Notfound from './pages/Notfound';
import Rewrite from './pages/Rewrite';

function RouterComponent() {

    /* 라우터 변경시 페이지 최상단 이동 */
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let currentPath = "";
        window.scrollTo(0, 0);

        if (currentPath === location.pathname) {
            window.location.reload();

            currentPath = location.pathname;
        }
    }, [location.pathname]);

    const allowedPaths = ["/manage/adminlist", "/manage/write", "/manage/rewrite/:boardId"];
    useEffect(() => {
        const handleBeforeUnload = () => {
          // 현재 경로가 특정 경로들 중 하나가 아닌 경우 sessionStorage를 클리어
          if (!allowedPaths.includes(location.pathname)) {
            sessionStorage.clear();
          }
        };
  
        // 페이지 닫을 때
        window.addEventListener('beforeunload', handleBeforeUnload);
        
        console.log(location, 'location');
        // 컴포넌트 언마운트 시
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [location, allowedPaths]);

    return (
        <Routes>
            
            <Route exact path="/" element={<Home />} />
            <Route path="/about/introduce" element={<Introduce />} />
            <Route path="/about/location" element={<Location />} />
            <Route path="/works" element={<Works />} />
            <Route path="/members" element={<Members />} />
            <Route path="/stories/news" element={<News />} />
            <Route path="/stories/victory" element={<Victory />} />
            <Route path="/stories/law" element={<Law />} />
            <Route path="/asks" element={<Asks />} />

            {/* 관리자페이지 */}
            <Route path="/manage/*" element={<Admin />} />
            <Route path="/manage/adminlist" element={sessionStorage.getItem('access_token') !== null ? <Adminlist /> : <Navigate to="/manage" />} />
            <Route path="/manage/write" element={sessionStorage.getItem('access_token') !== null ? <Write /> : <Navigate to="/manage" />} />
            <Route path="/manage/rewrite/:boardId" element={sessionStorage.getItem('access_token') !== null ? <Rewrite /> : <Navigate to="/manage" />} />
            {/* <Route path="/manage/rewrite/:boardId" element={<Rewrite />}  /> */}
            {/* Notfound 페이지 */}
            <Route path="*" element={<Notfound />} />
            
        </Routes>
    )
}

export default RouterComponent;