import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';

import { useNavigate } from 'react-router-dom';

import "../styles/write.css"
import axios from 'axios';

function Write() {

    const navigate = useNavigate();


    const [selectedOption, setSelectedOption] = useState('');
    const [fileImage, setFileImage] = useState([]);
    const [imageListCount, setImageListCount] = useState(0);
    
    const handleChange2 = (event) => {
        const selectedId = event.target.options[event.target.selectedIndex].getAttribute("data-id");
        setSelectedOption(selectedId);
    };

    const fileInput = useRef();

    const onReset = () => {
        fileInput.current.value = "";
    };
    const addImage = (e) => {
        setFileImage([]);
        const selectImageList = e.target.files;
        setImageListCount(selectImageList.length);
        const imageUrlList = [...fileImage];
        imageUrlList.pop(0);
        imageUrlList.pop(1);
      
        // 확장자 검사 및 이미지 개수 제한
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        let invalidFileCount = 0;
        for (let i = 0; i < selectImageList.length; i++) {
          const file = selectImageList[i];
          const fileExtension = file.name.split('.').pop().toLowerCase();
          if (!allowedExtensions.includes(fileExtension)) {
            invalidFileCount++;
          }
        }
      
        if (invalidFileCount > 0) {
          alert('jpg, jpeg, png 파일만 업로드할 수 있습니다.');
          setFileImage([]);
          fileInput.current.value = '';
        } else if (selectImageList.length - invalidFileCount > 2) {
          alert('이미지는 2개까지만 가능합니다.');
          setFileImage([]);
          fileInput.current.value = '';
        } else {
          for (let i = 0; i < selectImageList.length; i++) {
            const imageUrl = URL.createObjectURL(selectImageList[i]);
            imageUrlList.push(imageUrl);
          }
          setFileImage(imageUrlList);
        }
      };

    const validate = (e) => {
        e.preventDefault();
        alert('필수항목을 확인해주세요');
    }

    return (
        <>
            <article className='write'>
                <div className='inner'>
                    <div className='top'>
                        <p className="breadCrumb">
                            관리자 로그인 &gt; 게시물 관리 &gt; <span>콘텐츠 업데이트</span>
                        </p>

                        <h2>콘텐츠 업데이트</h2>
                        <p className="notice">* 는 필수항목입니다.</p>
                    </div>
                    <Formik
                        initialValues={{
                            classification: '',
                            author: '',
                            law_branch: '',
                            title: '',
                            content: '',
                            image: null,
                            image2: null,
                            youtube_url: '',
                            article_url: '',
                            article_name: '',
                            keyword1: '',
                            keyword2: '',
                            keyword3: '',
                            keyword4: ''
                        }}

                        validate={values => {
                            const errors = {};

                            if(values.classification === '분류선택' || !values.classification) {
                                errors.classification = '분류를 선택해주세요.'
                            }

                            if(values.author === '담당 변호사 선택' || !values.author){
                                errors.author = '담당 변호사를 선택해주세요.'
                            }

                            if(!values.title) {
                                errors.title = '제목을 입력해주세요.'
                            }else if (!(values.title.length < 50)){
                                errors.title = '제목은 50글자 이내'
                            }

                            if(selectedOption === '승소사례' && (values.law_branch === '분야선택' || !values.law_branch)){
                                errors.law_branch = '분야를 선택해주세요.'
                            }

                            return errors;
                        }}

                        onSubmit={(values) => {
                            const { classification , author, law_branch, title, content, image, image2, youtube_url, article_url, article_name, keyword1, keyword2, keyword3, keyword4 } = values

                            const submitValue = {
                                classification, author, law_branch, title, content, image, image2, youtube_url, article_url, article_name, keyword1, keyword2, keyword3, keyword4
                            };

                            //axios.post("http://www.jdlawfirm.co.kr:8003/manager/board", submitValue,{
                            axios.post("https://www.jdlawfirm.co.kr/manager/board", submitValue,{                                
                            headers:{
                                'Content-Type': 'multipart/form-data',
                            },
                            withCredentials: true, // Origin 헤더를 포함하도록 설정
                            })
                            .then((response) => {
                                if (!response || !response.data) {
                                    console.log('Empty or undefined response received from the server.');
                                    return;
                                }
                                console.log(response, 'res');
                            })
                            .catch((error) => {
                                console.log(error);
                                alert('글 작성 실패!');
                            })
                            alert(JSON.stringify('글 작성 완료!'));
                            navigate('/manage/adminlist');
                        }}
                    >

                        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
                            <form className="write-frm" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>글쓰기 폼</legend>
                                    <div className="sheet">
                                        <label>분류<span> *</span></label>
                                        <select name="classification" value={values.classification} onChange={(event) => {handleChange(event); handleChange2(event);}} onBlur={handleBlur}>
                                            <option>분류선택</option>
                                            <option value="정도소식" data-id="정도소식">정도소식</option>
                                            <option value="승소사례" data-id="승소사례">승소사례</option>
                                            <option value="법률정보" data-id="법률정보">법률정보</option>
                                        </select>
                                        <ErrorMessage name="classification" component="span" className="error" />
                                    </div>

                                    <div className="sheet">
                                        <label>담당자<span> *</span></label>
                                        <select name='author' value={values.author} onChange={handleChange} onBlur={handleBlur}>
                                            <option>담당 변호사 선택</option>
                                            <option>이한본 변호사</option>
                                            <option>양창영 변호사</option>
                                            <option>설창일 변호사</option>
                                            <option>김병재 변호사</option>
                                            <option>김지미 변호사</option>
                                        </select>
                                        <ErrorMessage name="author" component="span" className="error" />
                                    </div>
                                    {selectedOption === '승소사례' && (
                                        <div className="sheet">
                                            <label>분야<span> *</span></label>
                                            <select name='law_branch' value={values.law_branch} onChange={handleChange}  onBlur={handleBlur}>
                                                <option>분야선택</option>
                                                <option>민사</option>
                                                <option>형사</option>
                                                <option>행정</option>
                                                <option>가사</option>
                                            </select>
                                            <ErrorMessage name="law_branch" component="span" className="error" />
                                        </div>
                                    )}
                                    <div className="sheet">
                                        <label>제목<span> *</span></label>
                                        <input type="text" id="title" name='title' value={values.title} onChange={handleChange} onBlur={handleBlur}></input>
                                        <ErrorMessage name="title" component="span" className="error" />
                                    </div>

                                    {values.image && imageListCount < 3 && (
                                    <div className="sheet img_preview" >
                                        <label>사진 미리보기</label>
                                            <div>
                                                {fileImage.map((image,id) => ( // 이미지가 업로드된 경우에만 미리보기를 표시
                                                    <img key={id} src={image} alt={`${image}-${id}`} width={300} height={300} />
                                                ))}
                                            </div>
                                    </div>
                                    )}
                                    
                                    <div className="sheet">
                                        <label>내용</label>
                                        
                                        <textarea name='content' value={values.content} onChange={handleChange}></textarea>
                                    </div>
                                    {(selectedOption === '정도소식' || selectedOption === '승소사례') && (
                                        <div className="sheet" >
                                            <div>
                                                <label>사진 업로드</label>
                                                <input type="file" name='image' onChange={(event) => {
                                                    setFieldValue('image', event.currentTarget.files[0]);
                                                    setFieldValue('image2', event.currentTarget.files[1]);
                                                    addImage(event);
                                                }} multiple='multiple' accept="image/jpeg,image/jpg,image/png" ref={fileInput}></input>
                                                <button type='button' className="delete" onClick={() => {onReset(); setFieldValue('image', null); setFieldValue('image2', null); setFileImage([]); values.image=''; values.image2='';}}>비우기</button>
                                            </div>
                                            <label></label>
                                            <span className='imageExplain'>이미지는 2개까지 선택 가능합니다.(.jpg, .jpeg, .png 파일만 업로드 가능)</span>
                                        </div>
                                    )}
                                    
                                    {(selectedOption === '정도소식' || selectedOption === '승소사례') && (
                                        <div className="sheet" >
                                            <label>영상 업로드</label>
                                            <input type="url" name='youtube_url' value={values.youtube_url} onChange={handleChange} placeholder="업로드 하려는 youtube url을 붙여넣기 하세요."></input>
                                        </div>
                                    )}
                                    {(selectedOption === '정도소식' || selectedOption === '승소사례') && (
                                        <div className="sheet" >
                                            <div>
                                                <label>관련기사 URL</label>
                                                <input type="url" name='article_url' autoComplete='off' value={values.article_url} onChange={handleChange} placeholder="관련기사 url을 붙여넣기 하세요."></input>
                                            </div>
                                            <div>
                                                <label>관련기사 이름</label>
                                                <input type="url" className='article_name' name='article_name' autoComplete='off' value={values.article_name} onChange={handleChange} placeholder="관련기사의 링크 이름을 적어주세요."></input>
                                            </div>
                                        </div>
                                    )}
                                    {selectedOption === '법률정보' && (
                                        <div className="sheet" >
                                            <label>키워드 입력</label>
                                            <input 
                                                type="text"
                                                name='keyword1'
                                                value={values.keyword1}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword1', values.keyword2);
                                                        setFieldValue('keyword2', values.keyword3);
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                            />
                                            <input 
                                                type="text"
                                                name='keyword2'
                                                value={values.keyword2}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword2', values.keyword3);
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                                disabled={!values.keyword1}
                                            />
                                            <input 
                                                type="text"
                                                name='keyword3'
                                                value={values.keyword3}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                                disabled={!values.keyword2}
                                            />
                                            <input type="text" name='keyword4' value={values.keyword4} onChange={handleChange} disabled={!values.keyword3}></input>
                                            <span>처음부터 순서대로 입력해주세요. (최대 4개까지 입력가능)</span>
                                        </div>
                                    )}
                                </fieldset>

                                <div className="btn-wrap">
                                    {(selectedOption === '정도소식' || selectedOption === '법률정보') && 
                                        (!values.classification || values.classification === '분류선택' ||
                                            !values.author || values.author ==='담당 변호사 선택' || !values.title) ?
                                                <button type="submit" className="cancel"  onClick={(event) => validate(event)} >작성완료</button>
                                    : selectedOption === '승소사례' && 
                                        (!values.classification || values.classification === '분류선택' ||
                                            !values.author || values.author === '담당 변호사 선택' ||
                                            !values.title || !values.law_branch || values.law_branch ==='분야선택') ?
                                                <button type="submit" className="cancel"  onClick={(event) => validate(event)} >작성완료</button>
                                    :
                                        <button type="submit" className="complete">작성완료</button>
                                    }
                                    
                                    <Link to="/manage/adminlist"><button className="cancel">취소</button></Link>
                                </div>
                            </form>

                        )}
                    </Formik>

                </div>
            </article>
        </>
    )
}

export default Write;