import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
    
import "../styles/adminlist.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRedo } from "@fortawesome/free-solid-svg-icons";
import Paging from "../components/Paging";
import loading from '../img/loading.gif';


function Adminlist() {
    const [data, setData] = useState([]);
    const [refreshKey, setRefreshKey] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    // 필터
    const [classification, setClassification] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [title, setTitle] = useState('');

    //페이징
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(20);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setIsLoading(true);
        //axios.get("http://www.jdlawfirm.co.kr:8003/manager/board")
        axios.get("https://www.jdlawfirm.co.kr/manager/board")
        .then((res) => {
            if (res.statusText !== 'OK') {
              throw new Error('Network response was not ok');
            }
            return res.data;
          })
        .then((json) => {
            const formattedData = json.map(item => ({
                ...item,
                write_date:formatDate(item.write_date)
            }));
            setData(formattedData);
            setIsLoading(false);
        })
        .catch((error)=> {
            console.log(error, 'error')
        });
        
    }, [refreshKey]);
    
    const handleSelect = () => {
        //axios.get("http://www.jdlawfirm.co.kr:8003/manager/board", {
        axios.get("https://www.jdlawfirm.co.kr/manager/board", {
            params:{
                classification : classification,
                startDate : startDate,
                endDate : endDate,
                title : title
            }
        })
        .then((res) => {
            if (res.statusText !== 'OK') {
              throw new Error('Network response was not ok');
            }
            return res.data;
        })
        .then((json) => {
            const formattedData = json.map(item => ({
                ...item,
                write_date:formatDate(item.write_date)
            }));
            setData(formattedData);
        })
        .catch((error)=> {
            console.log(error, 'error')
        });
    }

    const handleSearchEnter = (e) => {
        if (e.key === "Enter") {
            handleSelect(e)
        }
    }

    const handleReset = () => {
        setClassification('')
        setStartDate('')
        setEndDate('')
        setTitle('')
        //axios.get("http://www.jdlawfirm.co.kr:8003/manager/board")
        axios.get("https://www.jdlawfirm.co.kr/manager/board")
        .then((res) => {
            if (res.statusText !== 'OK') {
              throw new Error('Network response was not ok');
            }
            return res.data;
          })
        .then((json) => {
            const formattedData = json.map(item => ({
                ...item,
                write_date:formatDate(item.write_date)
            }));
            setData(formattedData);
        })
        .catch((error)=> {
            console.log(error, 'error')
        });
    }

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const setPage = (pageNum) => {
        setCurrentPage(pageNum);
    };

    const logoutHandler = () => {
        // sessionStorage.removeItem("access_token")
        let token = sessionStorage.getItem('token')
        sessionStorage.clear()
    }

    return (
        <>
            <article className='admin_list'>
                <div className='inner'>
                    <div className='tit_box'>
                        <p className='breadCrumb'>
                            관리자 로그인 &gt; <span>게시물 관리</span>
                        </p>
                        <h2>게시물 관리</h2>
                        <div className="loginbox">
                            <p>{sessionStorage.getItem('name')}님 반갑습니다.</p>
                            <Link to="/manage/"><button onClick={logoutHandler}>로그아웃</button></Link>
                        </div>
                    </div>

                    <div className="pannel">
                        <div className="category">
                            <span>분류</span>
                            <select value={classification} onChange={(e) => setClassification(e.target.value)} onKeyPress={(e) => handleSearchEnter(e)}>
                                <option>분류선택</option>
                                <option>정도소식</option>
                                <option>승소사례</option>
                                <option>법률정보</option>
                            </select>
                        </div>
                        <div className="upload_date">
                            <span>업로드날짜</span>
                            <input type="date" name="start_date" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                            <span className="hypen">~</span>
                            <input type="date" name="end_date" value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                        </div>
                        {/* onKeyPress={(e) => handleSearchEnter(e)} */}
                        <div className="title">
                            <span>제목</span>
                            <input type="search" value={title} onChange={(e) => setTitle(e.target.value)} onKeyUp={(e) => handleSearchEnter(e)}></input>
                            
                        </div>
                        <div className='filter'>
                        <button
                            onClick={handleSelect}
                            type="submit"
                            className="ask"
                            >
                            <FontAwesomeIcon icon={faSearch} />
                            </button>
                            <button
                            className="reset"
                            onClick={handleReset}
                            >
                            <FontAwesomeIcon icon={faRedo} />
                            </button>
                        </div>
                        <div className="write">
                            <Link to="/manage/write">
                            <button>글쓰기</button>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="list">
                    {currentPosts && data.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>번호</th>                            
                                    <th>업로드날짜</th>
                                    <th>분류</th>
                                    <th>제목</th>
                                    <th>작성자</th>
                                    <th>마지막 수정날짜</th>
                                    <th>관리</th>
                                </tr>
                            </thead>
                        
                            {currentPosts.map((data, idx) => (
                                <tbody key={idx}>
                                    <tr>
                                    
                                        <td>{indexOfFirstPost+idx+1}</td>                               
                                        <td>{data.write_date}</td>
                                        <td>{data.classification}</td>
                                        <td><Link to={`/manage/rewrite/${data.board_id}`}>{data.title}</Link></td>
                                        <td>{data.author}</td>
                                        <td>{data.update_date}</td>
                                    
                                        <td>
                                        <Link to={`/manage/rewrite/${data.board_id}`}><button className="revise">수정</button></Link>
                                            <button className="delete" onClick={() => {
                                                if(window.confirm("정말 삭제합니까?")){
                                                    alert("삭제되었습니다.");
                                                    //axios.delete(`http://45.115.154.121:8000/manager/${data.board_id}`)
                                                    //axios.delete(`http://www.jdlawfirm.co.kr:8003/manager/${data.board_id}`)
                                                    axios.delete(`https://www.jdlawfirm.co.kr/manager/${data.board_id}`)
                                                    .then(response => {
                                                        console.log(response)
                                                        setRefreshKey(refresh => refresh * -1)
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                                }else{
                                                    alert("취소했습니다.");
                                                }
                                            }}>삭제</button>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    )
                    // ) : currentPosts && data.length == 0 
                    // ?
                    //     <p className='None'>해당 게시물이 없습니다.</p>
                    :
                        <div className='None'>
                            <img src={loading} alt="로딩중입니다."></img>
                            <p >게시물을 불러오는 중입니다.</p>
                        </div>
                    }
                        
                    </div>
                    
                    {/* <Link to="#">
                                        [부동산] 대법원 파기환송 판결 대법원 파기환송 판결 대법원 파기환송 판결 대법원 파기환송 판결
                                        </Link> */}

                    <Paging page={currentPage} count={data.length} setPage={setPage} />

                </div>
            </article>
        </>
    )
}

export default Adminlist