import React from 'react'
import {Link} from 'react-router-dom'
import { useRef, useState, useEffect} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";


import "../styles/Works.css"

import work1 from '../img/work_img1.PNG'
import work2 from '../img/work_img2.PNG'
import work3 from '../img/work_img3.PNG'
import work4 from '../img/work_img4.PNG'
import work5 from '../img/work_img5.PNG'
import work6 from '../img/work_img6.PNG'
import work7 from '../img/work_img7.PNG'
import page1 from '../img/page1.jpg'
import page2 from '../img/page2.jpg'
import pagePreView from '../img/page_preview.jpg'

function Works() {
    const [isActive, setIsActive] = useState(0);
    const tabRefs = useRef([]);
    /* const observerRef = useRef(null); */

    

    /* 메인페이지 연결 스크롤 */
    useEffect(() => {
        const handleResize = () => {
            if (window.location.hash) {
                const targetDiv = document.querySelector(window.location.hash);
                //console.log(targetDiv.offsetTop)
                setTimeout(() => {
                    if (targetDiv) {    
                        window.scrollTo({top: targetDiv.offsetTop-200, behavior: 'smooth',
                        });
                    }
                },200)
            }
        }
        handleResize();// 페이지 로드 시 현재 hash 값에 따라 스크롤 위치 조정

        window.addEventListener('resize', handleResize);// 창 크기 변경 시 이벤트 리스너 호출

        // 컴포넌트 unmount 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        
    },[])


    /* 스크롤 */
    useEffect(() => {
        const handleScroll = () => {
    
          // 페이지의 모든 섹션에 대한 id 리스트를 가정합니다.
          const sectionIds=['civil_affairs', 'real_estate', 'compensation_for_damages', 'criminal_case','labor','family_litigation','fair_trade',];
    
          for(let id of sectionIds){
            let element=document.getElementById(id)
            
            if(element) {
              // 현재 스크롤 위치가 해당 섹션 내부인지 확인
              if(window.pageYOffset >= element.offsetTop-400 && window.pageYOffset < element.offsetTop + element.offsetHeight) 
              setIsActive(id);
            }
          
         }
    
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
           window.removeEventListener('scroll', handleScroll);
        };
         
    }, []);


    /* 탭 */
    const handleTabClick = (tagId) => {
    
        const targetSection = tabRefs.current.find((ref) => ref.id === tagId);
        if (targetSection) {
            const offsetTop = targetSection.offsetTop;
            //console.log(`tab: ${targetSection.offsetTop}`)
            window.scrollTo({top: offsetTop-200});
            setIsActive(tagId);
        };
        
    };

    /* 대법원 판결문 토글 */
    const [judgmentOpen, setJudgmentOpen] = useState(false);
    const toggleOpen = () => {
        setJudgmentOpen(!judgmentOpen)
    }
    const ref = useRef(null);
    const handleCloseClick = () => {
        setJudgmentOpen(!judgmentOpen)
        window.scrollTo({
          top: ref.current.offsetTop,
        });
    };
    

    return (
    <div className='works'>
    <div className='inner'>
        <div className='tab_fixed'>
            <div className='tab_fixed_inner'>
                <p className='breadCrumb'>
                    <Link to="/">HOME</Link> &gt; <span>업무분야</span>
                </p>
                <div className='tab_component'>
                    <ul className='tab_items'>
                        <li className='tab_item'><Link to='#civil_affairs' onClick={() => handleTabClick('civil_affairs')} className={isActive === 'civil_affairs' ? 'active': ''}>일반민사</Link></li>
                        <li className='tab_item'><Link to='#real_estate' onClick={() => handleTabClick('real_estate')} className={isActive === 'real_estate' ? 'active': ''}>부동산</Link></li>
                        <li className='tab_item'><Link to='#compensation_for_damages' onClick={() => handleTabClick('compensation_for_damages')} className={isActive === 'compensation_for_damages' ? 'active': ''}>손해배상</Link></li>
                        <li className='tab_item'><Link to='#criminal_case' onClick={() => handleTabClick('criminal_case')} className={isActive === 'criminal_case' ? 'active': ''}>형사</Link></li>
                        <li className='tab_item'><Link to='#labor' onClick={() => handleTabClick('labor')} className={isActive === 'labor' ? 'active': ''}>노동</Link></li>
                        <li className='tab_item'><Link to='#family_litigation' onClick={() => handleTabClick('family_litigation')} className={isActive === 'family_litigation' ? 'active': ''}>가사<span className='item_span'>이혼&middot;상속</span></Link></li>
                        <li className='tab_item'><Link to='#fair_trade' onClick={() => handleTabClick('fair_trade')} className={isActive === 'fair_trade' ? 'active': ''}>공정거래</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='work_items'>
            <div id='civil_affairs' ref={(el) => (tabRefs.current[0] = el)} className="work_item" >
                <div ref={ref} className='work_item_main'>
                    <h1 className='work_name'>일반민사</h1>
                    <img src={work1} alt="일반민사이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                        우리가 일상생활을 하면서 통상적으로 하는 거의 모든 행위들은 민사 영역입니다. 예외적으로 법의 영역이 아닌 부분이 있을 수도 있지만, 법의 영역이 아닌지 맞는지를 판단하는 것도 결국은 민사 재판에 의하기 때문에 <span>형법, 상법, 행정법 등이 적용되지 않는 부분은 거의 모두 민사 영역</span>이라고 볼 수가 있습니다.
                        <br />
                        <br />
                        민법은 행위능력, 주소, 법인, 물건, 법률행위, 기간, 소멸시효, 점유권, 소유권, 지상권, 지역권, 전세권, 유치권, 질권, 저당권, 채권, 채무, 계약, 증여, 매매, 환매, 교환, 소비대차, 사용대차, 임대차, 고용, 도급, 여행계약, 현상광고, 위임, 임치, 조합, 종신정기금, 화해, 사무관리, 부당이득, 불법행위 등에 대하여 규정을 하고 있는데, 다른 법률에 특별한 규정이 없는 경우에는 이러한 민법의 규정을 해석하여 분쟁을 해결하게 됩니다. 
                        <br />
                        <br />
                        민법은 가장 기본적인 법률이고, 가장 많은 사건이 발생하는 영역이 일반 민사 영역입니다. 법무법인 정도는 특정 분야에만 전문성을 가진 법인이 아니라 <span>일상생활에서 가장 중요하고 보편적으로 활용되는 일반 민사 분야에 좋은 성과를 보이는 법인</span>입니다.
                        <br />
                        <br />
                        법무법인 정도는 2021년에 <span>민사 분야&#40;임대차&#41;에서 고등법원의 판결 결과를 대법원에서 뒤집어 다시 판결하게 하는 성과&#40;대법원 2021. 11. 25. 선고 2019다285257 판결&#41;</span>를 만들어내는 등 일반 민사 분야를 가장 많이 다루고 있고, 완벽한 자문업무를 수행하고, 승소를 만들어 내는 법인입니다. 
                    </div>
                    <div className='detail'>
                        <div className='detail_flex_center'>
                            <p className='detail_bold'>대법원 2021. 11. 25. 선고 2019다285257 판결</p>
                            <p className='toggle_icon' onClick={toggleOpen}>
                                <FontAwesomeIcon icon={judgmentOpen ? faCaretUp: faCaretDown} /><span>{judgmentOpen ? '접기' : '펼치기'}</span>
                            </p>
                        </div>
                        {judgmentOpen ? (
                            <div>
                                <img className='detail_page' src={page1} alt="판결문 1페이지" />
                                <img className='detail_page bt' src={page2} alt="판결문 2페이지" />
                                <div className='toggle_icon_bt' onClick={handleCloseClick} >
                                    <FontAwesomeIcon icon={faCaretUp} />
                                    <span>닫기</span>
                                </div>
                            </div>
                        ) : (
                            <img className='detail_page bt' src={pagePreView} alt="판결문 미리보기" />
                        )}
                        
                    </div>
                </div>
            </div>
            <div id='real_estate' ref={(el) => (tabRefs.current[1] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>부동산</h1>
                    <img src={work2} alt="부동산이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                        부동산 관련 법률은 원칙적으로는 민사법이지만, 부동산 관련 분쟁이 발생하면 민법 이외에 다양한 법률이 적용되는 경우가 많습니다. 법무법인 정도는 <span>부동산 관련한 다양한 법률에도 풍부한 경험과 노하우</span>를 가지고 있으며, 많은 승소사례를 만들어 가고 있습니다.
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>집합건물에서의 누수, 소음 등의 분쟁, 입주자대표회의, 공동주택관리단 관련 분쟁</li>
                            <li className='detail_li'>부동산 매매, 부동산 개발 및 건설·건축 관련 분쟁</li>
                            <li className='detail_li'>부동산 임대차, 유치권, 저당권, 인도&#40;명도&#41; 등 부동산 관련 법률관계</li>
                            <li className='detail_li'>부동산 경매, 공매 사건</li>
                            <li className='detail_li'>재건축, 재개발사업, 토지의 조성, 건축물의 신축과 개축</li>
                            <li className='detail_li'>일조권 분쟁, 건설 관련 보증, 담보, 하자보수, 공사대금 등</li>
                            <li className='detail_li'>부동산 개발사업, 자본시장과 금융투자에 관한 법률, 부동산투자회사법, 신탁법, 주택임대차보호법, 상가건물 임대차보호법</li>
                            <li className='detail_li'>부동산 관련 기타 법률문제 및 부동산과 관련된 행정규제</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='compensation_for_damages'  ref={(el) => (tabRefs.current[2] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>손해배상</h1>
                    <img src={work3} alt="손해배상이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                        손해배상 소송이란, 일반적으로 교통사고, 산업재해 사고, 의료사고, 놀이기구나 폭행에 의한 사고 등 <span>모든 종류의 사고로 인하여 사람이 사망 또는 부상을 입거나 재물이 파손되는 등으로 손해를 입었을 경우</span> 가해자나 보험회사 등을 상대로 손해의 배상을 청구하는 소송을 의미하고, 기타 발생한 손해를 누군가에게 청구하는 모든 소송을 포함합니다. 
                        <br />
                        <br />
                        사람이 사망하거나 부상을 입은 경우에 대한 법원의 손해배상액 산출기준은 사고의 종류를 불문하고 동일합니다. 즉, 법원이 인정하는 손해배상액의 종류는 크게 5가지로 구분되는데, 그것은 <span>“치료비, 개호비&#40;간병비&#41;, 보장구비용, 일실수익, 위자료”</span>입니다.
                        <br />
                        <br />
                        이때, 공무원연금법, 산업재해보상보험법 등 다른 법령이나 당사자 사이의 부분적인 합의에 따라 일부 금액을 지급받은 경우 이를 공제하게 되는데, 특히 산재사고의 경우 산업재해보상보험에 따라 근로복지공단으로부터 요양급여, 휴업급여, 장해급여, 간병급여 등이 지급되지만, 그 지급액이 <span>법원에서 인정하는 손해배상의 범위에 미달</span>하는 경우가 있고, 그러한 경우는 별도로 소송을 제기할 수 있습니다.
                        <br />
                        <br />
                        법무법인 정도는 <span>손해배상에 관한 다양한 경력과 전문성을 갖춘 변호사와 사무직원으로 손해배상팀을 운영</span>하고 있으며, 고객이 신속하고 적절하게 피해를 복구할 수 있도록 최선의 법률서비스를 제공하기 위하여 노력하고 있음을 자부합니다.
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>교통사고</li>
                            <li className='detail_li'>의료사고</li>
                            <li className='detail_li'>산업재해</li>
                            <li className='detail_li'>기타 다양한 사고로 인한 손해배상</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='criminal_case' ref={(el) => (tabRefs.current[3] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>형사</h1>
                    <img src={work4} alt="형사이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                    아무리 착실하고 성실하게 살아도 형사사건에 연루될 수 있습니다. 흔히 일어나는 교통사고뿐 아니라 게시판에 쓴 글 때문에 명예훼손 문제가 발생하기도 하고, 직장 내에서 발생한 사고로 인하여 상급자가 형사책임을 지는 경우도 있으며, 무심코 내려받은 컴퓨터 파일을 사용하였다가 저작권의 문제가 발생하는 경우도 있습니다. 또한, 지인에게 빌려준 돈을 받지 못하고 있는데, 채무자가 자신의 명의로는 재산이 없지만, 배우자의 명의로 재산을 숨겨 놓고 있는 경우에는 민사소송으로 해결이 안 되는 경우도 있습니다.
                    <br />
                    <br />
                    2021년 검찰, 경찰의 수사권 조정에 따라 형사 사건의 양상은 많은 부분에서 변화가 있었습니다. 검사가 직접 수사하고 경찰을 지휘하는 과거의 형사사법 시스템이 변경되면서 경찰 단계에서 수사 대부분이 종료되게 되어 형사 <span>피해자, 형사 피의자 양쪽 모두 더욱더 법률전문가의 조력이 필요</span>하게 되었습니다.
                    <br />
                    <br />
                    법무법인 정도는 수많은 <span>형사사건의 변론을 맡아왔고, 수많은 무죄, 불기소의 사례를 보유하고 있으며, 많은 수의 고소 대리, 피해자 변호 사건도 수행</span>하고 있습니다.
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>성폭력&#40;강간, 준강간, 강제추행&#41;, 강력범죄</li>
                            <li className='detail_li'>교통사고</li>
                            <li className='detail_li'>경제범죄&#40;사기, 횡령, 배임 등&#41;</li>
                            <li className='detail_li'>기타 특별법상의 범죄</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='labor' ref={(el) => (tabRefs.current[4] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>노동</h1>
                    <img src={work5} alt="노동이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                        법무법인 정도는 노동 전문 법인은 아닙니다. 그럼에도 불구하고 법무법인 정도는 근로자의 해고 사건에서 현직 변호사를 대리하여 사용자인 <span>대형 로펌을 상대로 승소, 외국 변호사를 대리하여 외국계 회사를 상대로 승소</span>하는 등 노동사건에서 변호사가 당사자로서 찾아오는 법인입니다.
                        <br />
                        <br />
                        <span>공공기관의 비정규직 근로자의 차별 시정 사건에서 대형 로펌을 상대로 하여 승소</span>한 사례도 있으며, 정부 부처, 공공기관의 <span>직장 내 성희롱, 직장 내 괴롭힘 사건의 조사</span>에 참여하거나 <span>고충처리위원회 위원</span>으로서 판정에 참여하는 등, 노동 분야에서도 탁월한 전문성을 가지고 있습니다.
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>부당해고, 부당징계, 부당전직, 부당노동행위</li>
                            <li className='detail_li'>해고무효, 징계무효, 전직처분 무효</li>
                            <li className='detail_li'>비정규직 근로자의 차별시정</li>
                            <li className='detail_li'>직장 내 성희롱, 직장 내 괴롭힘</li>
                            <li className='detail_li'>급여와 수당, 퇴직금 등의 청구</li>
                            <li className='detail_li'>산업재해</li>
                            <li className='detail_li'>노사관계법에 따른 단체교섭, 단체협약 및 노사분쟁과 관련된 문제</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='family_litigation' ref={(el) => (tabRefs.current[5] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>가사&#40;이혼&middot;상속&#41;</h1>
                    <img src={work6} alt="손해배상이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                        가족법은 원래 민법의 한 영역이지만, 가사소송법이 따로 존재하기 때문에 일반 민사와는 다른 원칙이 적용됩니다. 가장 중요한 특징은 당사자가 주장하지 않은 내용도 법원이 직권으로 판단할 수 있다는 것이고, 가족관계와 관련된 분쟁이기 때문에 <span>의뢰인과 변호사의 신뢰관계</span>가 중요합니다.
                        <br />
                        <br />
                        가사소송, 특히 이혼소송은 전문가임을 자처하며 언론 매체를 통해 알려지거나 광고를 통하여 사건을 수임하는 변호사들이 많습니다. 이혼소송 전문가로 알려진 대부분의 변호사들은 사건을 직접 담당하지 못합니다. 이름을 알린 변호사가 상담만 하여 사건을 수임하면 여러 명의 고용 변호사를 통하여 사건을 진행합니다. 그러다 보니, 의뢰인과 신뢰 관계의 문제가 발생 되어 변호사를 바꾸거나 사임하는 경우가 가장 많은 분야가 가족법 분야입니다.
                        <br />
                        <br />
                        법무법인 정도는 가사소송의 경우 <span>변호사들이 직접 의뢰인과 상담</span>을 하고, <span>상담을 담당한 변호사가 소송을 담당하는 원칙</span>을 가지고 단순히 소송만 수행하는 것이 아니라 의뢰인의 아픈 가족사를 공감하며 소송 진행과 소송의 종료 이후의 법률 문제와 대책을 함께 고민하고 있습니다. 
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>협의이혼과 협의에 의한 재산분할 상담</li>
                            <li className='detail_li'>이혼, 위자료, 재산분할, 양육권자 지정, 양육비에 대한 통합 솔루션</li>
                            <li className='detail_li'>상속재산 분할</li>
                            <li className='detail_li'>유류분</li>
                            <li className='detail_li'>상속포기, 상속 한정승인, 성본변경, 성년 후견 등 가사 비송</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='fair_trade' ref={(el) => (tabRefs.current[6] = el)} className="work_item">
                <div className='work_item_main'>
                    <h1 className='work_name'>공정거래</h1>
                    <img src={work7} alt="공정거래이미지" />
                </div>
                <div className='work_item_info'>
                    <div className='desc'>
                    공정거래분야는 거래상 우월한 지위에 있는 대기업과 거래하는 <span>수많은 중소기업이나 소상공인이 사업상 경험하는 위법한 사례를 시정</span>하기 위한 분야입니다. 나아가 소비자의 지위에서 금융, 보험, 통신 등 다양한 계약이 약관으로 이뤄지는 경우 소<span>비자의 권리가 침해되고 손해를 입는 것을 바로 잡는 분야</span>이기도 합니다. 거래 형태가 다양하고 복잡하기 때문에 면밀히 검토하고 사업자의 처지에 맞게 대응해야 합니다. 

                    법무법인 정도는 경제적 약자와 정보의 약자 편에서 진지하게 사정을 듣고 최선의 해결방안을 모색하고 있으며, 다양한 사례의 경험을 통해 최고의 전문성을 가지고 있습니다.  
                    </div>
                    <div className='detail'>
                        <p className='detail_bold'>상세업무</p>
                        <ul className='detail_items'>
                            <li className='detail_li'>하청업체의 하도급대금 청구, 불공정하도급거래 신고, 하도급거래분쟁조정 신청</li>
                            <li className='detail_li'>가맹사업자와 가맹본부 사이의 불공정거래 신고와 손해배상청구, 분쟁조정 신청</li>
                            <li className='detail_li'>대리점과 공급자 사이의 불공정거래 신고와 손해배상청구, 분쟁조정 신청</li>
                            <li className='detail_li'>대규모점포 입점업체나 납품업체와 대규모점포 사업자 사이의 불공정거래 신고, 분쟁조정신청, 손해배상 청구</li>
                            <li className='detail_li'>불공정약관심사청구와 손해배상청구 </li>
                            <li className='detail_li'>중소기업의 일반 불공정행위 신고 및 손해배상청구 </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default Works