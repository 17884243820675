import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';

import '../../styles/Header.css';




function Header({ className }) {
  // Header mouseenter, leave 상태관리
  const [isHeaderMouseEntered, setIsHeaderMouseEntered] = useState(false);

  // Header scroll 상태관리
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  const updateHeaderMouseState = useCallback(() => {
    if (location.pathname === '/') {
      if (!isHeaderMouseEntered && window.scrollY === 0) {
        // 마우스가 벗어나거나, scrollY값이 0일때 Header 스타일
        document.querySelector('header').style.backgroundColor = 'transparent';
        document.querySelector('header').style.borderBottom = '0';

        document.querySelectorAll('.gnb > ul > li > a').forEach((element) => {
          element.style.color = '#fff';
        });
      } else {
        // 마우스가 들어오거나, scrollY값이 0이 아닐 때 Header 스타일
        document.querySelector('header').style.backgroundColor = '#fff';
        document.querySelector('header').style.borderBottom = '1px solid #ccc';

        document.querySelectorAll('.gnb > ul > li > a').forEach((element) => {
          element.style.color = '#333';
        });
      }
    } else {
      // 서브 페이지일 때 Header 스타일
      document.querySelector('header').style.backgroundColor = '#fff';
      document.querySelector('header').style.borderBottom = '1px solid #ccc';

      document.querySelectorAll('.gnb > ul > li > a').forEach((element) => {
        element.style.color = '#333';
      });
    }
  }, [isHeaderMouseEntered, location]);

  const handleMouseEnter = () => {
    setIsHeaderMouseEntered(true);
    updateHeaderMouseState();
  };

  const handleMouseLeave = () => {
    setIsHeaderMouseEntered(false);
    updateHeaderMouseState();
  };

  useEffect(() => {
    updateHeaderMouseState();
  }, [isHeaderMouseEntered, isScrolled, location, updateHeaderMouseState]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    updateHeaderMouseState();
  }, [isScrolled, location, updateHeaderMouseState]);


  /* 서브메뉴 이벤트 */

  const handleSubmenuMouseEnter = (event) => {
    const target = event.currentTarget.querySelector('.gnb_2depth');
    if (target) {
      target.classList.add('active');
    }
  };

  const handleSubmenuMouseLeave = (event) => {
    const target = event.currentTarget.querySelector('.gnb_2depth');
    if (target) {
      target.classList.remove('active');
    }
  };


  /***** 모바일 ******/

  /* 햄버거버튼 이벤트 */

  const menuBtnToggle = (event) => {
    const target = event.currentTarget;

    const mobileMenuBg = document.querySelector('.mobile_menu_bg');
    const gnb1Depth = document.querySelector('.gnb_1depth');
    const Scrollx = document.querySelector('body'); 

    if (target) {
      target.classList.toggle('on');
      mobileMenuBg.classList.toggle('on');
      gnb1Depth.classList.toggle('on');
      Scrollx.classList.toggle('on');

    }
  };


  /* 모바일 메뉴 클릭 시 메뉴바 원상복귀 */

  const gnbClicked = (event) => {

    const MenuBg = document.querySelector('.mobile_menu_bg');
    const gnb1 = document.querySelector('.gnb_1depth');
    const menuButton = document.querySelector('.menu_btn');
    const Scrollx = document.querySelector('body'); 

    MenuBg.classList.remove('on');
    gnb1.classList.remove('on');
    menuButton.classList.remove('on');
    Scrollx.classList.remove('on');
  }

  useEffect(() => {


    const gnbItem = document.querySelectorAll('.gnb a');
    const logoHome = document.querySelector('.header_logo');

    gnbItem.forEach(item => item.addEventListener('click', gnbClicked));
    logoHome.addEventListener('click', gnbClicked);

  });

  /* 현재페이지 메뉴 클릭시 최상단 이동 */
  const locationNow = useLocation();

  const handleClick = (e, to) => {
    if (locationNow.pathname === to) {
      e.preventDefault();
      window.scrollTo({ top: 0/* , behavior: "smooth"  */ });
    }
  }


  return (
    <header
      className={`header ${location.pathname === '/' ? 'home' : 'sub'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="mobile_menu_bg"></div>
      <div className="inner">
        <h1 className="header_logo">
          <Link to="/" onClick={(e) => handleClick(e, "/")} className={isHeaderMouseEntered || isScrolled || location.pathname !== '/' ? 'logo_on' : 'logo_off'}>
            logo
          </Link>
        </h1>
        <nav className="gnb">
          <Fade duration={1000}>
            <ul className="gnb_1depth">
              <li className="gnb_1depth_li" onMouseEnter={handleSubmenuMouseEnter} onMouseLeave={handleSubmenuMouseLeave}>
                <Link to="/about/introduce" onClick={(e) => handleClick(e, "/about/introduce")}>정도소개</Link>
                <ul className="gnb_2depth">
                  <li className="gnb_2depth_li">
                    <Link to="/about/introduce" onClick={(e) => handleClick(e, "/about/introduce")}>정도(正道)</Link>
                  </li>
                  <li className="gnb_2depth_li">
                    <Link to="/about/location" onClick={(e) => handleClick(e, "/about/location")}>오시는 길</Link>
                  </li>
                </ul>
              </li>
              <li className="gnb_1depth_li">
                <Link to="/works" onClick={(e) => handleClick(e, "/works")}>업무분야</Link>
              </li>
              <li className="gnb_1depth_li">
                <Link to="/members" onClick={(e) => handleClick(e, "/members")}>변호사소개</Link>
              </li>
              <li className="gnb_1depth_li" onMouseEnter={handleSubmenuMouseEnter} onMouseLeave={handleSubmenuMouseLeave}>
                <Link to="/stories/news" onClick={(e) => handleClick(e, "/stories/news")}>정도이야기</Link>
                <ul className="gnb_2depth">
                  <li className="gnb_2depth_li">
                    <Link to="/stories/news" onClick={(e) => handleClick(e, "/stories/news")}>정도소식</Link>
                  </li>
                  <li className="gnb_2depth_li">
                    <Link to="/stories/victory" onClick={(e) => handleClick(e, "/stories/victory")}>승소사례</Link>
                  </li>
                  <li className="gnb_2depth_li">
                    <Link to="/stories/law" onClick={(e) => handleClick(e, "/stories/law")}>법률정보</Link>
                  </li>
                </ul>
              </li>
              <li className="gnb_1depth_li">
                <Link to="/asks" onClick={(e) => handleClick(e, "/asks")}>온라인문의</Link>
              </li>
            </ul>
          </Fade>
        </nav>
        <div className="menu_btn" onClick={menuBtnToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>


    </header>

  );
}

export default Header;