import React, {useState} from "react";
import { useCustomEffect } from "../components/useCustomEffectVictory";
import {Link} from  "react-router-dom";
import "../styles/Victory.css";
import VictoryItems from "../components/VictoryItems";
import VictoryModal from "../components/VictoryModal";

// import lawyer1 from '../img/lawyer_01.jpg' //양창영
/* import lawyer2 from '../img/lawyer_02.jpg' */ //설창일
// import lawyer3 from '../img/lawyer_03.jpg' //이한본
// import lawyer4 from '../img/lawyer_04.jpg' //김병재
// import lawyer5 from '../img/lawyer_05.jpg' //이영근
// import lawyer6 from '../img/lawyer_06.jpg' //김용기

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRedo } from "@fortawesome/free-solid-svg-icons";

function Victory() {
    /* 배열 */
    
    /* 그리드 변경 customHook */
    const { sep1Active, sep2Active, sep1Grid, sep2Grid, sep3Grid, cardsToShow, searchTerm, onRefresh, inputValue, setInputValue, handleSearch, showMore, handleShowMore, victoryArray } = useCustomEffect()
    console.log(victoryArray, 'victoryArray')
    /* 모달 popup */
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ title: "", content: "", image: null, image2: null, author: "", law_branch:"", write_date: "", article_url: "", article_name: "", youtube_url:"", update_date: ""});
    console.log(modalInfo, 'modalInfo')
    const handleCardClick = (title, content, image, image2, author, law_branch, write_date, article_url, article_name, youtube_url, update_date) => {
        setModalInfo({ title, content, image, image2, author, law_branch, write_date, article_url, article_name, youtube_url, update_date});
        setShowModal(true);
        document.body.style.overflow = 'hidden';
        
        //sep1에서 모달창 끔
        const screenWidth = window.innerWidth;
        if(screenWidth > 767 && sep1Active) {
            setShowModal(false);
            document.body.style.overflow = '';
        }
    };

    const closeModal = (e) => {
        if(e.target === e.currentTarget) {
            setShowModal(false);
            document.body.style.overflow = '';
        }
    };
    
    return (
        <div className='stories victory'>
            <div className='stories_top'>
                <p className='breadCrumb inner'>
                <Link to="/">HOME</Link> &gt; <Link to="/stories/news">정도이야기</Link> &gt; <span> 승소사례 </span>
                </p>
            </div>
            <div className='stories_main inner'>
                <div className='stories_main_title'>승소사례</div>
                <div className='search'>
                    <form onSubmit={handleSearch}>
                        <input type="text" placeholder='검색어를 입력하세요.' value={inputValue} onChange={(event) => {setInputValue(event.target.value);}} />
                        <button type="submit" className="searchBtn"><FontAwesomeIcon icon={faSearch} /></button>
                        <button className="refreshBtn" onClick={onRefresh}><FontAwesomeIcon icon={faRedo} /></button>
                    </form>
                </div>
                <div className='grid_btn'>
                    <div className='grid_btn_sep1' onClick={sep1Grid}></div>
                    <div className='grid_btn_sep2'onClick={sep2Grid}>
                        <div className='sep2_box left'></div>
                        <div className='sep2_box right'></div>
                    </div>
                    <div className='grid_btn_sep3 'onClick={sep3Grid}>
                        <div className='top'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                        <div className='bottom'>
                            <div className='sep3_box left'></div>
                            <div className='sep3_box right'></div>
                        </div>
                    </div>
                </div>
                <div className={`post_box_list ${sep1Active ? "sep1": ""}${sep2Active ? "sep2": ""}`}>
                {victoryArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter((data) => {
                        if (searchTerm === ''){
                            return data;
                        }else if(
                            data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            data.content.toLowerCase().includes(searchTerm.toLowerCase())
                        ) {
                            return data;
                        }
                    }).map((data) => (
                    <VictoryItems
                    key={data.board_id} 
                    title={data.title}
                    author={data.author} 
                    law_branch={data.law_branch} 
                    content={data.content}
                    image={data.image}
                    image2={data.image2}
                    write_date={data.write_date}
                    update_date={data.update_date}
                    youtube_url={data.youtube_url}
                    article_url={data.article_url}
                    article_name={data.article_name}
                    onClick={() => {handleCardClick(data.title, data.content, data.image, data.image2, data.author, data.law_branch, data.write_date, data.article_url, data.article_name, data.youtube_url, data.update_date)}} />
                ))}
                </div>
                {searchTerm !== "" && victoryArray.sort((a,b) => b.id-a.id).slice(0, cardsToShow).filter(data =>
                    data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    data.content.toLowerCase().includes(searchTerm.toLowerCase())
                ).length === 0 && (
                <div className="nothing">검색 결과가 없습니다.</div>
                )}
                {searchTerm === "" && cardsToShow<victoryArray.length && (<button className="viewMore" onClick={handleShowMore}>{showMore ? "더보기+":"접기"}</button>)}
                <VictoryModal 
                showModal={showModal} 
                closeModal={closeModal}
                key={modalInfo.id} 
                title={modalInfo.title}
                content={modalInfo.content}
                author={modalInfo.author}
                law_branch={modalInfo.law_branch} 
                image={modalInfo.image}
                image2={modalInfo.image2}
                write_date={modalInfo.write_date}
                article_url={modalInfo.article_url}
                article_name={modalInfo.article_name}
                youtube_url={modalInfo.youtube_url}
                update_date={modalInfo.update_date}
                />
            </div>
        </div>
    )
}

export default Victory