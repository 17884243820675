import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/notfound.css";

import warnning from '../img/warnning.png';

function Notfound() {
  return (
    <div className='notfound'>
        <div className="inner">
            <div className="warnning">
                <img src={warnning} alt="경고" />
                <p className="page_404">404 Not Found</p>
                <h2>페이지를 찾을 수 없습니다.</h2>
                <p className="txt">
                  찾으려는 페이지의 주소가 잘못 입력되었거나, <br></br>
                  주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다. <br></br>
                  입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
                </p>

                <Link to="/">홈으로 이동 </Link>
            </div>
        </div>
    </div>
  )
}

export default Notfound