import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';

import { useNavigate, useParams } from 'react-router-dom';

import "../styles/write.css"
import axios from 'axios';

function Rewrite() {

    const params = useParams();

    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('');
    const [data, setData] = useState([]);
    const [fileImage, setFileImage] = useState([]);
    const [imageListCount, setImageListCount] = useState(0);
    const [updateImage, setUpdateImage] = useState(false);
    const [alterImage, setAlterImage] = useState('');
    
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [showImage1, setShowImage1] = useState('');
    const [showImage2, setShowImage2] = useState('');
    
    const handleChange2 = (event) => {
        const selectedId = event.target.options[event.target.selectedIndex].getAttribute("data-id");
        setSelectedOption(selectedId);
    };

    useEffect (() => {
        //axios.get(`http://45.115.154.121:8000/manager/${params.boardId}`)
        axios.get(`https://www.jdlawfirm.co.kr/manager/${params.boardId}`)
        .then(response => {
            setData(response.data)
            if (response.data.image !== null){
                // setShowImage1(response.data.image.slice(17))
                setShowImage1('image1')
            }
            if(response.data.image2 !== null){
                // setShowImage2(response.data.image2.slice(17))
                setShowImage2('image2')
            }
            setImage1(response.data.image)
            setImage2(response.data.image2)

            if (response.data.image === null && response.data.image2 === null){
                setFileImage([]);
            } else if (response.data.image !== null && response.data.image2 === null){
                setFileImage([response.data.image]);
            } else {
                setFileImage([response.data.image, response.data.image2]);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [params.boardId])


    const fileInput = useRef();

    const onReset = () => {
        fileInput.current.value = "";
    };

    const addImage = (e) => {

    };

    const validate = (e) => {
        alert('필수항목을 확인해주세요')
    }

    const initialValues = {
        classification: data.classification,
        author: data.author,
        law_branch: data.law_branch,
        title: data.title,
        content: data.content,
        image: image1,
        image2: image2,
        youtube_url: data.youtube_url,
        article_url: data.article_url,
        article_name: data.article_name,
        keyword1: data.keyword1,
        keyword2: data.keyword2,
        keyword3: data.keyword3,
        keyword4: data.keyword4
    }

    const alterHandler = () => {
        setAlterImage('수정')
    }

    const alterCancelHandler = () => {
        setAlterImage('취소')
        //axios.get(`http://www.jdlawfirm.co.kr:8003/manager/${params.boardId}`)
        axios.get(`https://www.jdlawfirm.co.kr/manager/${params.boardId}`)
        .then(response => {
            setData(response.data)
            if (response.data.image !== null){
                // setShowImage1(response.data.image.slice(17))
                setShowImage1('image1')
            }
            if(response.data.image2 !== null){
                // setShowImage2(response.data.image2.slice(17))
                setShowImage2('image2')
            }
            setImage1(response.data.image)
            setImage2(response.data.image2)

            if (response.data.image === null && response.data.image2 === null){
                setFileImage([]);
            } else if (response.data.image !== null && response.data.image2 === null){
                setFileImage([response.data.image]);
            } else {
                setFileImage([response.data.image, response.data.image2]);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return (

        <>
            <article className='write'>
                <div className='inner'>
                    <div className='top'>
                        <p className="breadCrumb">
                            관리자 로그인 &gt; 게시물 관리 &gt; <span>콘텐츠 업데이트</span>
                        </p>

                        <h2>콘텐츠 업데이트</h2>
                        <p className="notice">* 는 필수항목입니다.</p>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}

                        validate={values => {
                            const errors = {};

                            if(values.classification === '분류선택' || !values.classification) {
                                errors.classification = '분류를 선택해주세요.'
                            }

                            if(values.author === '담당 변호사 선택' || !values.author){
                                errors.author = '담당 변호사를 선택해주세요.'
                            }

                            if(!values.title) {
                                errors.title = '제목을 입력해주세요.'
                            }else if (!(values.title.length < 50)){
                                errors.title = '제목은 50글자 이내'
                            }

                            if(selectedOption === '승소사례' && (values.law_branch === '분야선택' || !values.law_branch)){
                                errors.law_branch = '분야를 선택해주세요.'
                            }

                            return errors;
                        }}

                        onSubmit={(values) => {

                            const { classification , author, law_branch, title, content, image, image2, youtube_url, article_url, article_name, keyword1, keyword2, keyword3, keyword4 } = values

                            const formData = new FormData();
                            formData.append('classification', classification);
                            formData.append('author', author);
                            formData.append('law_branch', law_branch);
                            formData.append('title', title);
                            formData.append('content', content);
                            formData.append('youtube_url', youtube_url);
                            formData.append('article_url', article_url);
                            formData.append('article_name', article_name);
                            formData.append('keyword1', keyword1);
                            formData.append('keyword2', keyword2);
                            formData.append('keyword3', keyword3);
                            formData.append('keyword4', keyword4);
                            
                            if (image) {
                                formData.append('image', image);
                            }

                            if (image2) {
                                formData.append('image2', image2);
                            }

                            if (alterImage === '수정') {
                                //axios.put(`http://www.jdlawfirm.co.kr:8003/manager/${params.boardId}`, formData,{
                                axios.put(`https://www.jdlawfirm.co.kr/manager/${params.boardId}`, formData,{
                                headers:{
                                    'Content-Type': 'multipart/form-data',
                                },
                                withCredentials: true, // Origin 헤더를 포함하도록 설정
                                })
                                .then((response) => {
                                })
                                .catch((error) => {
                                    alert('글 수정 실패!')
                                })
                                alert(JSON.stringify('글 수정 완료!'))
                                navigate('/manage/adminlist')
                            } else {
                                //axios.put(`http://www.jdlawfirm.co.kr:8003/manager/image_ori/${params.boardId}`, formData,{
                                axios.put(`https://www.jdlawfirm.co.kr/manager/image_ori/${params.boardId}`, formData,{
                                headers:{
                                    'Content-Type': 'multipart/form-data',
                                },
                                withCredentials: true, // Origin 헤더를 포함하도록 설정
                                })
                                .then((response) => {
                                    alert(JSON.stringify('글 수정 완료!'))
                                    navigate('/manage/adminlist')
                                })
                                .catch((error) => {
                                    alert('글 수정 실패!')
                                })
                            }
                        }}
                    >

                        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
                            <form className="write-frm" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>글쓰기 폼</legend>
                                    <div className="sheet">
                                        <label htmlFor='classification'>분류<span> *</span></label>
                                        {data.classification === "정도소식"
                                        ? 
                                        <select name="classification" value={values.classification || ""} onChange={(event) => {handleChange(event); handleChange2(event);}}  onBlur={handleBlur}>
                                            <option>분류선택</option>
                                            <option value="정도소식" data-id="정도소식" selected>정도소식</option>
                                            <option value="승소사례" data-id="승소사례">승소사례</option>
                                            <option value="법률정보" data-id="법률정보">법률정보</option>
                                        </select>
                                        
                                        : data.classification === "승소사례"
                                        ?
                                        <select name="classification" value={values.classification || ""} onChange={(event) => {handleChange(event); handleChange2(event);}}  onBlur={handleBlur}>
                                            <option>분류선택</option>
                                            <option value="정도소식" data-id="정도소식">정도소식</option>
                                            <option value="승소사례" data-id="승소사례" selected>승소사례</option>
                                            <option value="법률정보" data-id="법률정보">법률정보</option>
                                        </select>
                                        :
                                        <select name="classification" value={values.classification || ""} onChange={(event) => {handleChange(event); handleChange2(event);}}  onBlur={handleBlur}>
                                            <option>분류선택</option>
                                            <option value="정도소식" data-id="정도소식">정도소식</option>
                                            <option value="승소사례" data-id="승소사례">승소사례</option>
                                            <option value="법률정보" data-id="법률정보" selected>법률정보</option>
                                        </select>
                                        }
                                        <ErrorMessage name="classification" component="span" className="error" />
                                    </div>

                                    <div className="sheet">
                                        <label>담당자<span> *</span></label>
                                        {data.author === '이한본 변호사'
                                        ?
                                        <select name='author' value={values.author || ""} onChange={handleChange} onBlur={handleBlur}>
                                            <option>담당 변호사 선택</option>
                                            <option selected>이한본 변호사</option>
                                            <option>양창영 변호사</option>
                                            <option>설창일 변호사</option>
                                            <option>김병재 변호사</option>
                                        </select>
                                        : data.author === '양창영 변호사'
                                        ?
                                        <select name='author' value={values.author || ""} onChange={handleChange} onBlur={handleBlur}>
                                            <option>담당 변호사 선택</option>
                                            <option>이한본 변호사</option>
                                            <option selected>양창영 변호사</option>
                                            <option>설창일 변호사</option>
                                            <option>김병재 변호사</option>
                                        </select>
                                        : data.author === '설창일 변호사'
                                        ?
                                        <select name='author' value={values.author || ""} onChange={handleChange} onBlur={handleBlur}>
                                            <option>담당 변호사 선택</option>
                                            <option>이한본 변호사</option>
                                            <option>양창영 변호사</option>
                                            <option selected>설창일 변호사</option>
                                            <option>김병재 변호사</option>
                                        </select>
                                        :
                                        <select name='author' value={values.author || ""} onChange={handleChange} onBlur={handleBlur}>
                                            <option>담당 변호사 선택</option>
                                            <option>이한본 변호사</option>
                                            <option>양창영 변호사</option>
                                            <option>설창일 변호사</option>
                                            <option selected>김병재 변호사</option>
                                        </select>
                                        }
                                        <ErrorMessage name="author" component="span" className="error" />
                                    </div>
                                    {(selectedOption === '승소사례' || data.classification === '승소사례') && (
                                        <div className="sheet">
                                            <label>분야<span> *</span></label>
                                            {data.law_branch === '민사'
                                            ?
                                            <select name='law_branch' value={values.law_branch || ""} onChange={handleChange} onBlur={handleBlur}>
                                                <option>분야 선택</option>
                                                <option selected>민사</option>
                                                <option>형사</option>
                                                <option>행정</option>
                                                <option>가사</option>
                                            </select>
                                            :data.law_branch === '형사'
                                            ?
                                            <select name='law_branch' value={values.law_branch || ""} onChange={handleChange} onBlur={handleBlur}>
                                                <option>분야 선택</option>
                                                <option>민사</option>
                                                <option selected>형사</option>
                                                <option>행정</option>
                                                <option>가사</option>
                                            </select>
                                            :data.law_branch === '행정'
                                            ?
                                            <select name='law_branch' value={values.law_branch || ""} onChange={handleChange} onBlur={handleBlur}>
                                                <option>분야 선택</option>
                                                <option>민사</option>
                                                <option>형사</option>
                                                <option selected>행정</option>
                                                <option>가사</option>
                                            </select>
                                            :
                                            <select name='law_branch' value={values.law_branch || ""} onChange={handleChange} onBlur={handleBlur}>
                                                <option>분야 선택</option>
                                                <option>민사</option>
                                                <option>형사</option>
                                                <option>행정</option>
                                                <option selected>가사</option>
                                            </select>
                                            }
                                            <ErrorMessage name="law_branch" component="span" className="error" />
                                        </div>
                                    )}
                                    <div className="sheet">
                                        <label>제목<span> *</span></label>
                                        <input type="text" id="title" name='title' value={values.title || ""} onChange={handleChange} onBlur={handleBlur}></input>
                                        <ErrorMessage name="title" component="span" className="error" />
                                    </div>

                                    {values.image && imageListCount < 3 &&  ( // 이미지가 업로드된 경우에만 미리보기를 표시
                                        // data.image == null &&  data.image2 == null?
                                        <div className="sheet img_preview" >
                                            <label>사진 미리보기</label>
                                                <div>
                                                    {fileImage.map((image,id) => (
                                                        <img key={id} src={image} alt={`${image}-${id}`} width={300} height={300}/>
                                                    ))}
                                                </div>
                                        </div>
                                        // : data.image != null && data.image2 == null?
                                        // <div className="sheet img_preview" >
                                        //     <label>사진 미리보기</label>
                                        //     <div>
                                        //         <img src={data.image} alt="Image Preview" width={300} height={300}/>
                                        //     </div>
                                        // </div>
                                        // : data.image == null ?
                                        // <div className="sheet img_preview" >
                                        //     <label>사진 미리보기</label>
                                        //     <div>
                                        //         <img src={data.image2} alt="Image Preview" width={300} height={300}/>
                                        //     </div>
                                        // </div>
                                        // :
                                        // <div className="sheet img_preview" >
                                        //     <label>사진 미리보기</label>
                                        //     <div>
                                        //         <img src={data.image} alt="Image Preview" width={300} height={300}/>
                                        //         <img src={data.image2} alt="Image Preview" width={300} height={300}/>
                                        //     </div>
                                        // </div>
                                    )}        

                                    <div className="sheet">
                                        <label>내용</label>
                                        
                                        <textarea name='content' value={values.content || ""} onChange={handleChange}></textarea>
                                    </div>
                                    {(selectedOption === '정도소식' || selectedOption === '승소사례' || data.classification === '정도소식' || data.classification === '승소사례') && (
                                        <div className="sheet" >
                                           
                                           {alterImage === '취소' ? 
                                                <>
                                                    {showImage1 !== null && showImage2 === null ? 
                                                        <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                            <p> {showImage1}</p>
                                                            <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                        </div>
                                                    :showImage1 !== null && showImage2 !== null ?
                                                        <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                            <p> {showImage1}</p>
                                                            <p> {showImage2}</p>
                                                            <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                        </div>
                                                    :
                                                        <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                            <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                        </div>

                                                    }
                                                </>
                                            : alterImage === '수정' ?
                                                <>
                                                    <div className="sheet2">
                                                    <label>사진 업로드</label>
                                                        <input type="file" name='image' onChange={(event) => {
                                                            setFieldValue('image', event.currentTarget.files[0]);
                                                            setFieldValue('image2', event.currentTarget.files[1]);

                                                            setFileImage([]);
                                                            const selectImageList = event.target.files;
                                                            setImageListCount(selectImageList.length);
                                                            const imageUrlList = [...fileImage];
                                                            imageUrlList.pop(0);
                                                            imageUrlList.pop(1);

                                                            if (selectImageList.length === 0) {
                                                                setFieldValue('image', null);
                                                                setFieldValue('image2', null);
                                                            } else if (selectImageList.length === 1) {
                                                                setFieldValue('image', event.currentTarget.files[0]);
                                                                setFieldValue('image2', null);
                                                            } else {
                                                                setFieldValue('image', event.currentTarget.files[0]);
                                                                setFieldValue('image2', event.currentTarget.files[1]);
                                                            }
                                                    
                                                            // 확장자 검사 및 이미지 개수 제한
                                                            const allowedExtensions = ['jpg', 'jpeg', 'png'];
                                                            let invalidFileCount = 0;
                                                            for (let i = 0; i < selectImageList.length; i++) {
                                                              const file = selectImageList[i];
                                                              const fileExtension = file.name.split('.').pop().toLowerCase();
                                                              if (!allowedExtensions.includes(fileExtension)) {
                                                                invalidFileCount++;
                                                              }
                                                            }
                                                          
                                                            if (invalidFileCount > 0) {
                                                              alert('jpg, jpeg, png 파일만 업로드할 수 있습니다.');
                                                              setFileImage([]);
                                                              fileInput.current.value = '';
                                                            } else if (selectImageList.length - invalidFileCount > 2) {
                                                              alert('이미지는 2개까지만 가능합니다.');
                                                              setFileImage([]);
                                                              fileInput.current.value = '';
                                                            } else {
                                                              for (let i = 0; i < selectImageList.length; i++) {
                                                                const imageUrl = URL.createObjectURL(selectImageList[i]);
                                                                imageUrlList.push(imageUrl);
                                                              }
                                                              setFileImage(imageUrlList);
                                                            }
                                                        }} multiple='multiple' accept="image/jpeg,image/jpg,image/png" ref={fileInput}></input>
                                                        <button type='button' onClick={alterCancelHandler}  className='img-revise-btn2'>수정취소</button>
                                                        <button type='button' className="delete" onClick={() => {onReset(); setFieldValue('image', null); setFieldValue('image2', null); setFileImage([]); values.image=''; values.image2=''; data.image=null; data.image2=null; setImage1(null);  setImage2(null);}}>비우기</button>
                                                        {/* <button type='button' className='updateCancel' onClick={() => setUpdateImage(false)}>수정취소</button> */}
                                                    </div>
                                                    <label></label>
                                                    <span className='imageExplain'>이미지는 2개까지 선택 가능합니다.(.jpg, .jpeg, .png 파일만 업로드 가능)</span>
                                                </>
                                            :
                                                <>
                                                    {showImage1 !== null && showImage2 === null ? 
                                                        <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                            <p> {showImage1}</p>
                                                            <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                        </div>
                                                    :showImage1 !== null && showImage2 !== null ?
                                                        <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                            <p> {showImage1}</p>
                                                            <p> {showImage2}</p>
                                                            
                                                            <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                        </div>
                                                    : showImage1 == null && showImage2 == null ?
                                                    <div className="sheet2">
                                                        <label>사진 업로드</label>
                                                        <button type='button' onClick={alterHandler} className='img-revise-btn1'>수정하기</button>
                                                    </div>
                                                :
                                                        <>
                                                            <div className="sheet2">
                                                            <label>사진 업로드</label>
                                                                <input type="file" name='image' onChange={(event) => {
                                                                    setFieldValue('image', event.currentTarget.files[0]);
                                                                    setFieldValue('image2', event.currentTarget.files[1]);
                                                                    setFileImage([]);
                                                                    const selectImageList = event.target.files;
                                                                    setImageListCount(selectImageList.length);
                                                                    const imageUrlList = [...fileImage];
                                                                    imageUrlList.pop(0);
                                                                    imageUrlList.pop(1);

                                                                    if (selectImageList.length === 0) {
                                                                        setFieldValue('image', null);
                                                                        setFieldValue('image2', null);
                                                                    } else if (selectImageList.length === 1) {
                                                                        setFieldValue('image', event.currentTarget.files[0]);
                                                                        setFieldValue('image2', null);
                                                                    } else {
                                                                        setFieldValue('image', event.currentTarget.files[0]);
                                                                        setFieldValue('image2', event.currentTarget.files[1]);
                                                                    }

                                                                    // 확장자 검사 및 이미지 개수 제한
                                                                    const allowedExtensions = ['jpg', 'jpeg', 'png'];
                                                                    let invalidFileCount = 0;
                                                                    for (let i = 0; i < selectImageList.length; i++) {
                                                                    const file = selectImageList[i];
                                                                    const fileExtension = file.name.split('.').pop().toLowerCase();
                                                                    if (!allowedExtensions.includes(fileExtension)) {
                                                                        invalidFileCount++;
                                                                    }
                                                                    }
                                                                
                                                                    if (invalidFileCount > 0) {
                                                                    alert('jpg, jpeg, png 파일만 업로드할 수 있습니다.');
                                                                    setFileImage([]);
                                                                    fileInput.current.value = '';
                                                                    } else if (selectImageList.length - invalidFileCount > 2) {
                                                                    alert('이미지는 2개까지만 가능합니다.');
                                                                    setFileImage([]);
                                                                    fileInput.current.value = '';
                                                                    } else {
                                                                    for (let i = 0; i < selectImageList.length; i++) {
                                                                        const imageUrl = URL.createObjectURL(selectImageList[i]);
                                                                        imageUrlList.push(imageUrl);
                                                                    }
                                                                    setFileImage(imageUrlList);
                                                                    }
                                                                }} multiple='multiple' accept="image/jpeg,image/jpg,image/png" ref={fileInput}></input>
                                                                <button type='button' className="delete" onClick={() => {onReset(); setFieldValue('image', null); setFieldValue('image2', null); setFileImage([]); values.image=''; values.image2=''; data.image=null; data.image2=null; setImage1(null);  setImage2(null);}}>비우기</button>
                                                                {/* <button type='button' className='updateCancel' onClick={() => setUpdateImage(false)}>수정취소</button> */}
                                                            </div>
                                                            <label></label>
                                                            <span className='imageExplain'>이미지는 2개까지 선택 가능합니다.(.jpg, .jpeg, .png 파일만 업로드 가능)</span>
                                                        </>

                                                    }
                                                </>
                                            }
                                        </div>
                                    )}
                                    {((selectedOption === '정도소식' || selectedOption === '승소사례') || (data.classification === '정도소식' || data.classification === '승소사례')) && (
                                        <div className="sheet" >
                                            <label>영상 업로드</label>
                                            <input type="url" name='youtube_url' value={values.youtube_url || ""} onChange={handleChange} placeholder="업로드 하려는 youtube url을 붙여넣기 하세요."></input>
                                        </div>
                                    )}
                                    {((selectedOption === '정도소식' || selectedOption === '승소사례') || (data.classification === '정도소식' || data.classification === '승소사례')) && (
                                        <div className="sheet" >
                                            <div>
                                            <label>관련기사 URL</label>
                                            <input type="url" name='article_url' value={values.article_url || ""} onChange={handleChange} placeholder="관련기사 url을 붙여넣기 하세요."></input>
                                            </div>
                                            <div>
                                                <label>관련기사 이름</label>
                                                <input type="url" className='article_name' name='article_name' value={values.article_name || ""} onChange={handleChange} placeholder="관련기사의 링크 이름을 적어주세요."></input>
                                            </div>
                                        </div>
                                    )}
                                    {(selectedOption === '법률정보' || data.classification === '법률정보') && (
                                        <div className="sheet" >
                                            <label>키워드 입력</label>
                                            <input 
                                                type="text"
                                                name='keyword1'
                                                value={values.keyword1 || ""}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword1', values.keyword2);
                                                        setFieldValue('keyword2', values.keyword3);
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                            />
                                            <input 
                                                type="text"
                                                name='keyword2'
                                                value={values.keyword2 || ""}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword2', values.keyword3);
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                                disabled={!values.keyword1}
                                            />
                                            <input 
                                                type="text"
                                                name='keyword3'
                                                value={values.keyword3 || ""}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if(!e.target.value) {
                                                        setFieldValue('keyword3', values.keyword4);
                                                        setFieldValue('keyword4', '');
                                                    }
                                                }}
                                                disabled={!values.keyword2}
                                            />
                                            <input type="text" name='keyword4' value={values.keyword4 || ""} onChange={handleChange} disabled={!values.keyword3}></input>
                                            <span>처음부터 순서대로 입력해주세요. (최대 4개까지 입력가능)</span>
                                        </div>
                                    )}
                                </fieldset>

                                <div className="btn-wrap">
                                    {(selectedOption === '정도소식' || selectedOption === '법률정보') && 
                                        (!values.classification || values.classification === '분류선택' ||
                                            !values.author || values.author ==='담당 변호사 선택' || !values.title) ?
                                                <button type="submit" className="cancel"  onClick={(event) => validate(event)} >수정완료</button>
                                    : selectedOption === '승소사례' && 
                                        (!values.classification || values.classification === '분류선택' ||
                                            !values.author || values.author === '담당 변호사 선택' ||
                                            !values.title || !values.law_branch || values.law_branch ==='분야선택') ?
                                                <button type="submit" className="cancel"  onClick={(event) => validate(event)} >수정완료</button>
                                    :
                                        <button type="submit" className="complete">수정완료</button>
                                    }
                                    <Link to="/manage/adminlist"><button className="cancel">취소</button></Link>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </article>
        </>
    )
}

export default Rewrite;