import axios from 'axios';
import {useState, useEffect} from 'react';

export const useCustomEffect = () => {
    /* 그리드 변경 */
    const [sep1Active, setSep1Active] = useState(false);
    const [sep2Active, setSep2Active] = useState(false);
    const [newsArray, setNewsArray] = useState([]);

    const sep1Grid = () => {
        setSep1Active(true);
        setSep2Active(false);
    };
    const sep2Grid = () => {
        setSep2Active(true);
        setSep1Active(false);
    };
    const sep3Grid = () => {
        setSep1Active(false)
        setSep2Active(false)
    };

    const imageUrl = (url) => {
        if (url == null){
            return null
        } else {
            return url;
        }
    }

    const youtubeUrl = (url) => {
        if (url.slice(0, 16) === "https://youtu.be"){
            url = url.replace("https://youtu.be", "https://www.youtube.com/embed")
        }
        return url
    }

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    /* 반응형에 따른 그리드 변경 */
    useEffect(() => {
        const checkScreenWidth = () => {
            const screenWidth = window.innerWidth;
    
            if (screenWidth > 1024) {
                sep3Grid();
            }else if(screenWidth > 767) {
                sep2Grid();
            }else{
                sep1Grid();
            }
        };
        // 컴포넌트 마운트 시 한 번 실행
        checkScreenWidth();
    
        // 창 크기 변경 시 실행
        const handleResize = () => {
          checkScreenWidth();
        };
    
        window.addEventListener('resize', handleResize);

        //axios.get("http://61.78.123.217:8000/news")
        //axios.get("http://www.jdlawfirm.co.kr:8003/news")
        axios.get("https://www.jdlawfirm.co.kr/manager/news")
        .then((res) => {
            if (res.statusText !== 'OK') {
              throw new Error('Network response was not ok');
            }
            return res.data;
          })
        .then((json) => {
        console.log(json, 'json')
        const formattedData = json.map(item => ({
            ...item,
            write_date: formatDate(item.write_date),
            image: imageUrl(item.image),
            image2: imageUrl(item.image2),
            youtube_url: youtubeUrl(item.youtube_url)
        }));
        setNewsArray(formattedData);
        console.log(formattedData, 'for')
        })
        .catch((error)=> {
            console.log(error, 'error')
        });
    
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    console.log(newsArray, 'newsAraay')

    /* 카드더보기 */
    const [cardsToShow, setCardsToShow] = useState(6);//현재 보여줄 카드 개수
    const [showMore, setShowMore] = useState(true); //더보기->접기 버튼 변경

    const handleShowMore = () => {
        if(showMore) {
            if(cardsToShow+6<newsArray.length){
                setCardsToShow(cardsToShow + 6);//카드 개수 3개씩 증가
            }else{
                setCardsToShow(newsArray.length);
                setShowMore(false)
            }
        }else{
            setCardsToShow(6);
            setShowMore(true)
            window.scrollTo({top: 0, behavior: 'smooth'});
          
        }
    };

    /* 검색 */
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(inputValue);
    }

    const onRefresh = () => {
        setSearchTerm('');
        setInputValue('');
        setCardsToShow(6);
    }

    return { sep1Active, sep2Active, sep1Grid, sep2Grid, sep3Grid, cardsToShow, searchTerm, onRefresh, inputValue, setInputValue, handleSearch, showMore, handleShowMore, newsArray };
};