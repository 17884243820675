import React, { useState, useRef } from 'react';
// import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import "../styles/asks.css";
import axios from 'axios';
import { set } from 'react-hook-form';

/* 
리액트 설치
npm install @emailjs/browser --save
npm install emailjs-com
*/

/* 이메일 발송함수 */
function Asks() {

  const form = useRef();

  //메일 발송 함수
  // const sendEmail = (e) => {
  //   e.preventDefault();
    
    // 필수 입력 필드 지정
    // const requiredFields = ['from_name', 'phone', 'user_email'];
    // let isValid = true;
    
    // requiredFields.forEach((field) => {
    //     const input = form.current[field];
        
    //     if (!input || input.value.trim() === '') {
    //         isValid = false;
    //     }
    // });
     
    // if (!isValid) {
    //     alert("필수 항목을 입력해주세요.");
    //     return;
    // } // 

    // 개인정보 수집이용 동의
    

    
    //메일 발송 로직
    // emailjs.sendForm('TestSangJun', 'template_8tr6bli', form.current, 'dhj9gV-Tio3EDa96g')
    //   .then((result) => {
    //     console.log(result.text);
    //     alert("상담신청이 완료되었습니다.");
    //   }, (error) => {
    //     console.log(error.text);
    //   }); //
  // };

  const [showPopup, setShowPopup] = useState(false);

  const showPopupHandler = () => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const hidePopupHandler = (e) => {

    if(e.target === e.currentTarget) {
      setShowPopup(false);
      document.body.style.overflow = '';
    }
    
  };

  const [author, setAuthor] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [lawBranch, setLawBranch] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // 필수항목검사
    if (author.trim() === '' || contact.trim() === '' || email.trim() === '') {
      alert("필수 항목을 입력해주세요.");

      return;
    }
    // 개인정보수집동의 검사
    const isChecked = form.current.agreement.checked;
    if (!isChecked) {
      alert("개인정보 수집·이용에 동의하셔야 상담신청이 가능합니다.");
      
      return;
    } 

    console.log(author, 'author')
    console.log(contact, 'contact')
    console.log(email, 'email')
    console.log(lawBranch, 'lawBranch')
    console.log(content, 'content')
    //axios.post('http://www.jdlawfirm.co.kr:8003/ask', {
    axios.post('https://www.jdlawfirm.co.kr/manager/ask', {      
        author: author,
        contact: contact,
        email: email,
        law_branch: lawBranch,
        content: content,
    })
    .then((response) => {
      console.log(response, 'res');
      alert(`${author}님의 문의가 발송되었습니다.`)
      setAuthor('')
      setContact('')
      setEmail('')
      setLawBranch('')
      setContent('')
    })
    .catch((error) => {
        console.log(error);
    })
  };

  return (
    <>
      <article className="asks">
        <div className='inner'>
          <div className='top'>
            <p className="breadCrumb">
            <Link to="/">HOME</Link> &gt; <span>온라인문의</span>
            </p>

            <h2>온라인 문의</h2>
            <p className="notice">* 는 필수항목입니다.</p>
          </div>

          <form ref={form} className="email_frm">

            <fieldset>
              <legend>상담신청 폼</legend>
                <div className="sheet user_name">
                  <label>작성자<span> *</span></label>
                  <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} name="from_name" placeholder='이름을 입력하세요.' />
                </div>

                <div className="sheet user_tel">
                  <label>연락처<span> *</span></label>
                  <input type="tel" value={contact} onChange={(e) => setContact(e.target.value)} name="phone" placeholder="'-'없이 입력하세요." />
                </div>

                <div className="sheet user_email">
                  <label>이메일<span> *</span></label>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="user_email" placeholder="이메일을 입력하세요." />
                </div>

                <div className="sheet works">
                  <label>분야</label>
                  <select value={lawBranch} onChange={(e) => setLawBranch(e.target.value)} name="work">
                    <option value="분야선택">분야선택</option>
                    <option value="일반민사">일반민사</option>
                    <option value="부동산">부동산</option>
                    <option value="손해배상">손해배상</option>
                    <option value="형사">형사</option>
                    <option value="노동">노동</option>
                    <option value="가사">가사(이혼&middot;상속)</option>
                    <option value="공정거래">공정거래</option>
                    <option value="기타">기타</option>
                  </select>
                </div>

                <div className="sheet text">
                  <label>내용</label>
                  <textarea value={content} onChange={(e) => setContent(e.target.value)} name="text" />
                </div>
            </fieldset>

            <div className="privacy">
                  <label>
                  <input type="checkbox" className="chk" id="agreement" name="agreement"></input>
                  개인정보 수집 및 이용동의
                  </label>
                  
                  <div className="pop-up-btn" onClick={showPopupHandler}>
                    <Link to="#">전문 보기</Link>
                  </div>
                </div>

            <input type="submit" value="작성완료" onClick={handleSubmit} />
          </form>

        </div>
      </article>

      <div className={`bg ${showPopup ? "visible" : ""}`} onClick={hidePopupHandler}>
          <div className={`pop-up ${showPopup ? "visible" : ""}`}>
              <h3>개인정보 수집&middot;이용 동의서</h3>
              <p>법무법인 정도(이하 '회사')는 귀하의 개인정보를 중요하게 생각하며 개인정보 보호법에 따라 안전하게 처리하고 있습니다. 회사는 개인정보보호법 제 17조에 근거하여 다음과 같이 귀하의 개인정보를 수집&middot;이용하고자 하오니 아래의 사항을 잘 읽어보시고 동의하여 주시기 바랍니다.</p>

              <h4>1. 개인정보 수집&middot;이용</h4>
              <p>회사 홈페이지를 통한 법률상담 관련 온라인 문의 서비스 제공</p>
              <h4>2. 수집&middot;이용 항목</h4>
              <p>성명, 연락처(전화번호, 휴대폰 번호), 이메일, 업무분야 및 기타 온라인 문의를 위하여 귀하가 제공한 내용에 포함되어 있는 일체의 개인정보</p>
              <h4>3. 보유&middot;이용기간</h4>
              <p>수집한 개인정보는 수집 &middot; 이용에 관한 동의일로부터 수집&middot;이용목적 달성 시까지, 또는 귀하가 동의를 철회할 때까지 보유&middot;이용됩니다.</p>
              <h4>4. 동의거부권 및 불이익</h4>
              <p>귀하는 위와 같은 개인정보의 수집 &middot; 이용을 거부할 수 있습니다. 다만, 필수적 정보의 수집&middot;이용에 관하여 동의하지 않을 경우 온라인 문의가 불가능합니다.</p>

              <button className="close-btn" onClick={hidePopupHandler}>확인</button>
          </div>
      </div>

    </>
  )
}

export default Asks;