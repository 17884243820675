import React from 'react'
/* import {Link} from 'react-router-dom' */
import { useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";

import "../styles/Members.css"

import member1 from '../img/member1.png'
import member2 from '../img/member2.png'
import member3 from '../img/member3.png'
import member4 from '../img/member4.png'
import member5 from '../img/member5.png'

function Members({}) {
    const [isActive, setIsActive] = useState(0);
    const tabRefs = useRef([]);

    /* 메인페이지 연결 */
    useEffect(() => {
        const handleResize = () => {
            if (window.location.hash) {
                const targetDiv = document.querySelector(window.location.hash);
                console.log(targetDiv.offsetTop)
                setTimeout(() => {
                    if (targetDiv) {    
                        window.scrollTo({top: targetDiv.offsetTop-170, behavior: 'smooth',
                        });
                    }
                },200)
            }
        }
        handleResize();// 페이지 로드 시 현재 hash 값에 따라 스크롤 위치 조정

        window.addEventListener('resize', handleResize);// 창 크기 변경 시 이벤트 리스너 호출

        // 컴포넌트 unmount 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[])    

    useEffect(() => {
        const handleScroll = () => {
    
          // 페이지의 모든 섹션에 대한 id 리스트를 가정합니다.
          const sectionIds=['lawyer-lee_han_bon', 'lawyer-yang_chang_yeong', 'lawyer-kim_byeong_jae', 'lawyer-seol_chang_il', 'lawyer-kim_ji_mi'];
    
          for(let id of sectionIds){
            let element=document.getElementById(id) 
            
            if(element) {
              // 현재 스크롤 위치가 해당 섹션 내부인지 확인
              if(window.pageYOffset >= element.offsetTop-400 && window.pageYOffset < element.offsetTop + element.offsetHeight) 
              setIsActive(id);
            }
          
         }
    
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
           window.removeEventListener('scroll', handleScroll);
        };
         
    }, []);




    /* 탭 */
    const handleTabClick = (tagId) => {
    
        const targetSection = tabRefs.current.find((ref) => ref.id === tagId);
        const offsetTop = targetSection.offsetTop;
        if (targetSection) {
            window.scrollTo({top: offsetTop-170});
            setIsActive(tagId);
        };
        
    };
    



    return (
    <div className='members sub'>
        <div className='inner'>
            <div className='tab_fixed'>
                <div className='tab_fixed_inner'>
                    <p className='breadCrumb'>
                        <Link to="/">HOME</Link> &gt; <span>변호사소개</span>
                    </p>
                    <div className='tab_component'>
                        <ul className='tab_items'>
                            <li className='tab_item'>
                                <Link to='#lawyer-lee_han_bon' onClick={() => handleTabClick('lawyer-lee_han_bon')} className={isActive === 'lawyer-lee_han_bon' ? 'active': ''}>변호사 이한본</Link>
                            </li>
                            <li className='tab_item'>
                                <Link to='#lawyer-yang_chang_yeong' onClick={() => handleTabClick('lawyer-yang_chang_yeong')} className={isActive === 'lawyer-yang_chang_yeong' ? 'active': ''}>변호사 양창영</Link>
                            </li>
                            <li className='tab_item'>
                                <Link to='#lawyer-kim_byeong_jae' onClick={() => handleTabClick('lawyer-kim_byeong_jae')} className={isActive === 'lawyer-kim_byeong_jae' ? 'active': ''}>변호사 김병재</Link>
                            </li>
                            <li className='tab_item'>
                                <Link to='#lawyer-seol_chang_il' onClick={() => handleTabClick('lawyer-seol_chang_il')} className={isActive === 'lawyer-seol_chang_il' ? 'active': ''}>변호사 설창일</Link>
                            </li>
                            <li className='tab_item'>
                                <Link to='#lawyer-kim_ji_mi' onClick={() => handleTabClick('lawyer-kim_ji_mi')} className={isActive === 'lawyer-kim_ji_mi' ? 'active': ''}>변호사 김지미</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='member_items'>
                <div id='lawyer-lee_han_bon' ref={(el) => (tabRefs.current[0] = el)} className="member_item">
                    <h1 className='phrase'>"사건만 보지 않고, 사람을 봅니다."</h1>
                    <div className='member_flex'>
                        <div className='member_item_main'>
                            <div className='member_img_wrap'>
                                <img src={member3} alt="이한본변호사" />
                            </div>
                        </div>
                        <div className='member_item_info'>
                            <h2 className='member_name'>이한본 변호사</h2>
                            <p className='contact'>
                                <span>E.</span>hanbonlee@gmail.com
                            </p>
                            <div className='detail_flex'>
                                <div className='detail_lf'>
                                    <div className='member_detail edu'>
                                        <h3>학력</h3>
                                        <p>
                                        대일외국어고등학교 졸업
                                        <br />
                                        연세대학교 법학과 졸업
                                        </p>
                                    </div>
                                    <div className='member_detail mainWork'>
                                        <h3>취급분야</h3>
                                        <ul>
                                            <li>이혼, 상속 등 가족법 분야</li>
                                            <li>손해배상(의료사고, 교통사고, 산재사고)</li>
                                            <li>해고, 비정규직 차별 등 노동법 분야</li>
                                            <li>형사(다수의 무죄, 불기소 처분 사례)</li>
                                            <li>성폭력, 성희롱, 직장내 괴롭힘</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='member_detail career'>
                                    <h3>경력</h3>
                                    <ul>
                                        <li>제 46회 사법시험 합격</li>
                                        <li>사법연수원 제 36기 수료</li>
                                        <li>전 법무법인 청솔 구성원 변호사</li>
                                        <li>서울여성노동자회 고용평등상담실 자문 변호사</li>
                                        <li>서울 양천경찰서 집회시위 자문위원</li>
                                        <li>전 서울시 경력단절 예방 지원단</li>
                                        <li>2011 UN 여성차별철폐협약(CEDAW) NGO 참가단</li>
                                        <li>2012-2017 민변 여성인권위원회 가족법팀장</li>
                                        <li>2014-2016 서울 양천경찰서 정보공개 심의위원</li>
                                        <li>2017-2018 서울특별시 양천구의회 자문변호사</li>
                                        <li>2018-2019 민변 여성인권위원회 부위원장</li>
                                        <li>2018 법무부 성희롱 성범죄 대책위원회 위원</li>
                                        <li>2018 문체부 성희롱 성폭력 예방대책위원회 위원</li>
                                        <li>2018-2020 중앙성별영향평가분석위원회 위원</li>
                                        <li>2019-2022 여가부 자문변호사</li>
                                        <li>2020-2022 법무부 양성평등정책위원회 위원</li>
                                        <li>2020-2024 국방부 양성평등위원회 위원</li>
                                        <li>2021-2022 국방부 군검찰 수사심의위원회 위원</li>
                                        <li>2022-2024 여가부 여성폭력방지위원회 위원</li>
                                        <li>2022-2024 여가부 적극행정위원회 위원</li>
                                        <li>2022-2024 중앙성별영향평가분석위원회 위원</li>
                                        <li>2023-2025 국가인권위원회 차별시정전문위원회 위원</li>
                                        <li>2023-2025 경기도 인권위원회 위원</li>
                                        <li>2024-2025 검찰 성별영향평가 자문위원회 위원</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='lawyer-yang_chang_yeong' ref={(el) => (tabRefs.current[1] = el)} className="member_item"> 
                    <h1 className='phrase'>"공정거래, 민생경제, 중소기업, 중소상인 분야의 탁월한 전문가"</h1>
                    <div className='member_flex'>
                        <div className='member_item_main'>
                            <div className='member_img_wrap'>
                                <div className='member_bg'></div>
                                <img src={member1} alt="양창영변호사"/>
                            </div>
                        </div>
                        <div className='member_item_info'>
                            <h2 className='member_name'>양창영 변호사</h2>
                            <p className='contact'>
                                <span>E.</span>cyyang16@naver.com
                            </p>
                            <div className='detail_flex'>
                                <div className='detail_lf'>
                                    <div className='member_detail edu'>
                                        <h3>학력</h3>
                                        <p>
                                        광주 송원고등학교 졸업
                                        <br />
                                        연세대학교 법학과 졸업
                                        </p>
                                    </div>
                                    <div className='member_detail mainWork'>
                                        <h3>취급분야</h3>
                                        <ul>
                                            <li>기업회계, 재무</li>
                                            <li>지적재산권</li>
                                            <li>세무</li>
                                            <li>건설, 건축(하자 소송)</li>
                                            <li>공정거래 분야</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='member_detail career'>
                                    <h3>경력</h3>
                                    <ul>
                                        <li>주식회사 대우 근무</li>
                                        <li>주식회사 대우인터내셔널 근무</li>
                                        <li>제50회 사법시험 합격</li>
                                        <li>사법연수원 제40기 수료</li>
                                        <li>법률사무소 원 변호사</li>
                                        <li>서울가정법원 국선보조인</li>
                                        <li>서울특별시 대리점분쟁조정위원회 위원</li>
                                        <li>중소벤처기업부 생계형적합업종심의위원회 위원</li>
                                        <li>중소벤처기업부 자체평가위원회 위원</li>
                                        <li>중소벤처기업부 사업조정심위원회 위원</li>
                                        <li>사단법인 소상공인연구원 이사</li>
                                        <li>주식회사 천랩 감사</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id='lawyer-kim_byeong_jae' ref={(el) => (tabRefs.current[2] = el)} className="member_item">
                    <h1 className='phrase'>"의뢰인의 든든한 동반자"</h1>
                    <div className='member_flex'>
                        <div className='member_item_main'>
                            <div className='member_img_wrap'>
                                <div className='member_bg'></div>
                                <img src={member4} alt="김병재변호사" />
                            </div>
                        </div>
                        <div className='member_item_info'>
                            <h2 className='member_name'>김병재 변호사</h2>
                            <p className='contact'>
                                <span>E.</span>  donbj@hanmail.net
                            </p>
                            <div className='detail_flex'>
                                <div className='detail_lf'>
                                    <div className='member_detail edu'>
                                        <h3>학력</h3>
                                        <p>
                                        광주 동신고등학교 졸업
                                        <br />
                                        전남대학교 법학과 졸업
                                        </p>
                                    </div>
                                    <div className='member_detail mainWork'>
                                        <h3>취급분야</h3>
                                        <ul>
                                            <li>민, 형사 및 가사사건</li>
                                            <li>부동산 및 건설 관련 소송</li>
                                            <li>상가임대차 관련 소송-권리금소송</li>
                                            <li>프랜차이즈(가맹점)관련 소송</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='member_detail career'>
                                    <h3>경력</h3>
                                    <ul>
                                        <li>중앙대 창업경영대학원 졸업(프랜차이즈학과-석사)</li>
                                        <li>43회 사법시험 합격</li>
                                        <li>사법연수원 제33기 수료</li>
                                        <li>법무법인 GL 구성원변호사</li>
                                        <li>법무법인 청솔 구성원변호사</li>
                                        <li>영등포구치소 교정시민 옴부즈만 위원</li>
                                        <li>중소기업 법률지원단 변호사</li>
                                        <li>서울경영정보고 학교폭력대책 자치위원</li>
                                        <li>에쉐르아이시네마쇼핑몰관리단 임시관리인</li>
                                        <li>등촌1구역주택재건축정비사업조합 임시조합장</li>
                                        <li>다수회사 주주총회의 검사인 위촉</li>
                                        <li>서울영등포청년회의서(JC) 회장(2011년)</li>
                                        <li>서울영등포로타리클럽(RC) 회장(2019년)</li>
                                        <li>대한법률구조공단 서울남부지부 법률구조위원</li>
                                        <li>서울영등포경찰서 생활안전협의회 위원</li>
                                        <li>염창동우성연립가로주택정비사업조합 법률자문위원</li>
                                        <li>라마다앙코르서울마곡호텔관리단 법률자문위원</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='lawyer-seol_chang_il' ref={(el) => (tabRefs.current[3] = el)} className="member_item">
                    <h1 className='phrase'>"분쟁방지, 신속해결, 최선의 결과"</h1>
                    <div className='member_flex'>
                        <div className='member_item_main'>
                            <div className='member_img_wrap'>
                                <div className='member_bg'></div>
                                <img src={member2} alt="설창일변호사" />
                            </div>
                        </div>
                        <div className='member_item_info'>
                            <h2 className='member_name'>설창일 변호사</h2>
                            <p className='contact'>
                                <span>E.</span>sullaw73@gmail.com
                            </p>
                            <div className='detail_flex'>
                                <div className='detail_lf'>
                                    <div className='member_detail edu'>
                                        <h3>학력</h3>
                                        <p>
                                        광주전남고등학교 졸업
                                        <br />
                                        국민대학교 법학과 졸업
                                        </p>
                                    </div>
                                    <div className='member_detail mainWork'>
                                        <h3>취급분야</h3>
                                        <ul>
                                            <li>민·형사 및 가사사건</li>
                                            <li>기업자문 및 기업송무</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='member_detail career'>
                                    <h3>경력</h3>
                                    <ul>
                                        <li>제43회 사법시험 합격</li>
                                        <li>사법연수원 제33기 수료</li>
                                        <li>법무법인 명문 구성원변호사</li>
                                        <li>경기도행정심판위원</li>
                                        <li>경기도공직자윤리위원</li>
                                        <li>엔노블주식회사 등 20개 기업체 자문사변호사 위촉</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='lawyer-kim_ji_mi' ref={(el) => (tabRefs.current[4] = el)} className="member_item">
                    <h1 className='phrase'>"형사전문, 행정전문 인권 변호사"</h1>
                    <div className='member_flex'>
                        <div className='member_item_main'>
                            <div className='member_img_wrap'>
                                <div className='member_bg'></div>
                                <img src={member5} alt="김지미변호사" />
                            </div>
                        </div>
                        <div className='member_item_info'>
                            <h2 className='member_name'>김지미 변호사</h2>
                            <p className='contact'>
                                <span>E.</span>bohemianjm@naver.com
                            </p>
                            <div className='detail_flex'>
                                <div className='detail_lf'>
                                    <div className='member_detail edu'>
                                        <h3>학력</h3>
                                        <p>
                                        부산 대명여자고등학교 졸업
                                        <br />
                                        부산대학교 법학과 졸업
                                        </p>
                                    </div>
                                    <div className='member_detail mainWork'>
                                        <h3>취급분야</h3>
                                        <ul>
                                            <li>형사 사건</li>
                                            <li>행정 사건</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='member_detail career'>
                                    <h3>경력</h3>
                                    <ul>
                                        <li>제 47회 사법시험 합격</li>
                                        <li>사법연수원 제 37기 수료</li>
                                        <li>법무법인 대양 변호사</li>
                                        <li>서울남부지방법원 국선전담변호사</li>
                                        <li>민주사회를위한변호사모임 상근 사무차장</li>
                                        <li>대통령직속 정책기획위원회 위원</li>
                                        <li>법무부 제2기 법무검찰개혁위원회 위원</li>
                                        <li>방송통신위원회 행정심판위원회 위원</li>
                                        <li>국회사무처 행정심판위원회 위원</li>
                                        <li>국회사무처 정보공개심의회 위원</li>
                                        <li>서울시 제1정보공개심의회 위원장</li>
                                        <li>국가인권위원회 정보공개심의회 위원</li>
                                        <li>국가인권위원회 자유권전문위원</li>
                                        <li>국가인권위원회 청원심의회 위원</li>
                                        <li>경제인문사회연구회 기획평가위원</li>
                                        <li>서울시 금천구 인사위원회 위원</li>
                                        <li>경기도 소방재난본부 인사위원회 위원</li>
                                        <li>충남연구원 인사위원회 위원</li>
                                        <li>민주사회를위한변호사모임 사법위원장</li>
                                        <li>대한변호사협회 사법인권소위원회 위원</li>
                                        <li>대한변호사협회 재심법률지원소위원회 위원</li>
                                        <li>서울지방변호사회 인권위원회 위원</li>
                                        <li>학교법인 광희학원 이사</li>
                                        <li>월촌초등학교, 영도초등학교 학교폭력대책 자치위원회 위원</li>
                                        <li>경기남부 자치경찰위원</li>
                                        <li>KBS 시청자위원</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    )
}

export default Members