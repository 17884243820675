import React from 'react'
import { Link } from 'react-router-dom';

import "../../styles/Footer.css"

import footerLogo from '../../img/footer_logo.png'


function Footer() {
  return (
    <footer className='footer'>
        <div className="inner">
            <img className='footer_logo' src={footerLogo} alt="footerlogo" />
            <ul className='address'>
                <li className='address_li'>
                    <address>정도주사무소 : 서울 양천구 신월로 377, 2층 (신정동, 진성빌딩) (우)08023</address>
                    <span> 전화 02-2692-9005 </span>
                    <span>팩스 02-2692-8007</span>
                </li>
                
                <li className='address_li'>
                    <address>안양분사무소 : 안양시 동안구 부림로 156, 203호 (관양동, 안양법조타운) (우) 14055</address>
                    <span> 전화 031-421-1777 </span>
                    <span>팩스 031-421-1776</span>
                </li>
                <li className='address_li'>
                    <address>천안분사무소 : 충남 천안시 동남구 청수7로 45, 5층 (청당동, 신협빌딩) (우) 31197</address>
                    <span> 전화 041-568-6700 </span>
                    <span>팩스 041-568-6702</span>
                </li>
                <li className='address_li'>
                    <address>평택분사무소 : 경기도 평택시 평남로 1045, 302호(동삭동, 손문빌딩) (우) 17849</address>
                    <span> 전화 031-692-5201 </span>
                    <span>팩스 031-692-5202 </span>
                </li>
                <li className='address_li' >
                    <address>서초분사무소 : 서울 서초구 법원로 15, 404호 (서초동, 정곡빌딩) (우) 06595</address>
                    <span> 전화 02-595-7088 </span>
                    <span>팩스 02-595-7089</span>
                </li>
                <li className='copyright'>
                    <Link to="/manage">&copy;</Link>
                2012 JEONGDO LAWFIRM. All Rights Reserved.</li>
            </ul>
        </div>

    </footer>
  )
}

export default Footer