import React from 'react'

function LawItems({ title, content, author, write_date, update_date, keyword1, keyword2, keyword3, keyword4, onClick}) { //onclick: 모달열림
  return (
    <div className='post_box'>
        {!keyword1 && !keyword2 && !keyword3 && !keyword4 ?
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        :!keyword2 && !keyword3 && !keyword4 ?
            <ul className='post_box_top_tag'>
                <li>#{keyword1}</li>
            </ul>
        :!keyword3 && !keyword4 ?
            <ul className='post_box_top_tag'>
                <li>#{keyword1}</li>
                <li>#{keyword2}</li>
            </ul>
        :!keyword4?
            <ul className='post_box_top_tag'>
                <li>#{keyword1}</li>
                <li>#{keyword2}</li>
                <li>#{keyword3}</li>
            </ul>
        :
            <ul className='post_box_top_tag'>
                <li>#{keyword1}</li>
                <li>#{keyword2}</li>
                <li>#{keyword3}</li>
                <li>#{keyword4}</li>
            </ul>
        }
        <div className='post_box_title_area' onClick={onClick}>
            <p className="post_box_title">
                {title? title:"제목을 입력해 주세요"}
            </p>
            <ul className="post_box_info">
                <li className="info_member">담당변호사 {author ? author:"정보없음"}</li>
                <li className="info_date">{write_date} (마지막 수정일: {update_date})</li>
            </ul>
        </div>
            <div className="post_box_content">
                <div className='post_box_desc' onClick={onClick}>
                    {content}
                </div>
                {!keyword1 && !keyword2 && !keyword3 && !keyword4 ?
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                :!keyword2 && !keyword3 && !keyword4 ?
                    <ul className='post_box_bottom_tag'>
                        <li>{keyword1}</li>
                    </ul>
                :!keyword3 && !keyword4 ?
                    <ul className='post_box_bottom_tag'>
                        <li>{keyword1}</li>
                        <li>{keyword2}</li>
                    </ul>
                :!keyword4?
                    <ul className='post_box_bottom_tag'>
                        <li>{keyword1}</li>
                        <li>{keyword2}</li>
                        <li>{keyword3}</li>
                    </ul>
                :
                    <ul className='post_box_bottom_tag'>
                        <li>{keyword1}</li>
                        <li>{keyword2}</li>
                        <li>{keyword3}</li>
                        <li>{keyword4}</li>
                    </ul>
                }
            </div>
        <div className='post_box_detail' onClick={onClick}>
            <p className='more_btn'>자세히보기</p>
            <p className="post_date">{write_date}</p>
        </div>
    </div>
  )
}

export default LawItems