import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "../styles/admin.css"
import axios from 'axios';

function Admin() {

    const navigate = useNavigate(); // useNavigate hook을 가져옴

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    // const handleLogin = (e) => {
    //     e.preventDefault();
    //     if (adminId === 'admin' && password === 'admin') {
    //         onLogin(); // Router컴포넌트의 상태 변경
    //         navigate('/manage/adminlist'); 
    //     } else {
    //         alert('올바른 ID와 비밀번호를 입력하세요.');
    //     }
    // };

    // const [accessToken, setAccessToken] = useState(window.sessionStorage.getItem('access_token'))

    // useEffect(() => {
    //     console.log(accessToken, 'at')
        
    // }, [accessToken])

    // const signButtonHandler = (event) => {
    //     event.preventDefault();

    //     axios.post("http://61.78.123.217:8000/signup/", {
    //     //axios.post("http://45.115.154.121:8000/login/", {
    //     //axios.post("http://www.jdlawfirm.co.kr:8003/signup/", {        
    //         id:id,
    //         password:password,
    //         name: "이한본"
    //     },
    //     {
    //         headers:{
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     .then((response) => {
    //         if(response.data.token.access){
    //             sessionStorage.setItem('name', response.data.user.name);
    //             sessionStorage.setItem('access_token', response.data.token.access);
    //             alert(`${response.data.user.name}님 로그인`);
    //             navigate('/manage');
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         alert('올바른 ID와 비밀번호를 입력하세요.')
    //     });
    // };

    const loginButtonHandler = (event) => {
        event.preventDefault();

        //axios.post("http://61.78.123.217:8000/login/", {
        //axios.post("http://45.115.154.121:8000/login/", {
        //axios.post("http://www.jdlawfirm.co.kr:8003/login/", {
        axios.post("https://www.jdlawfirm.co.kr/manager/login/", {
            id:id,
            password:password
        },
        {
            headers:{
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(response.data.token.access){
                sessionStorage.setItem('name', response.data.user.name);
                sessionStorage.setItem('access_token', response.data.token.access);
                alert(`${response.data.user.name}님 로그인`);
                navigate('/manage/adminlist');
            }
        })
        .catch((error) => {
            console.log(error);
            alert('올바른 ID와 비밀번호를 입력하세요.')
        });
    };

    return (
        <>
        <article className="login-wrap">
            <form className="login-frm" method="post">
                <h2>관리자 로그인</h2>
                
                <label>
                    관리자ID
                    <input type="text"  value={id} onChange={(e) => setId(e.target.value)} autoComplete="off"></input>
                </label>
                <label>
                    비밀번호
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password"></input>
                </label>

                <button type="submit" onClick={loginButtonHandler}>Login</button>
                {/*<button type="submit" onClick={signButtonHandler}>회원가입</button>*/}
            </form>
        </article>
        </>
    )
}

export default Admin;