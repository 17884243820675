import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import "../../styles/sidemenu.css"

import S_icon01 from '../../img/side_icon01.png';
import S_icon02 from '../../img/side_icon02.png';
import S_icon03 from '../../img/side_icon03.png';

function SideMenu() {

    // 현재 경로(location)을 가져옵니다.
  const location = useLocation();

  // 현재 경로에 "manage"가 포함되어 있는지 검사합니다.
  const isAdminPage = location.pathname.includes("manage");

  // 만약 관리자 페이지라면 SideMenu를 숨깁니다.
  if (isAdminPage) {
    return null;
  }

  return (
    <div className="side_menu">
        <nav>
            <ul>
                <li>
                    <Link to="tel:02-2692-9005">
                        <div className="img-wrap">
                        <img src={S_icon01} alt="상담전화" />
                        </div>
                        <p>상담전화</p>
                        <span>02-2692-9005</span>
                    </Link>
                </li>
                <li>
                    <Link to="/asks">
                        <div className="img-wrap">
                        <img src={S_icon02} alt="상담안내" />
                        </div>
                        <p>상담안내</p>
                    </Link>
                </li>
                <li>
                    <Link to="/about/location">
                        <div className="img-wrap">
                        <img src={S_icon03} alt="오시는길" />
                        </div>
                        <p>오시는 길</p>
                    </Link>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default SideMenu;